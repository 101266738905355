import * as React from 'react';

const Footer = () => {
	return (
		<div
			style={{
				backgroundImage: 'linear-gradient(var(--footer-background-gradient-start), var(--footer-background-gradient-end))',
				height: '56px',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				position: 'fixed',
				bottom: 0,
				zIndex: 9999
			}}
		>
			<svg
				style={{
					flex: '1 1 auto',
					height: '24px',
					color: 'var(--footer-logo-color)'
				}}
				version="1.0"
				xmlns="http://www.w3.org/2000/svg"
				width="1139.000000pt"
				height="251.000000pt"
				viewBox="0 0 1139.000000 251.000000"
				preserveAspectRatio="xMidYMid meet"
			>
				<g transform="translate(0.000000,251.000000) scale(0.100000,-0.100000)" fill="var(--footer-logo-color)" stroke="none">
					<path d="M0 1255 l0 -1255 5695 0 5695 0 0 1255 0 1255 -5695 0 -5695 0 0
							-1255z m11190 0 l0 -1055 -5495 0 -5495 0 0 1055 0 1055 5495 0 5495 0 0
							-1055z" />
					<path d="M610 1765 l0 -135 215 0 215 0 0 -510 0 -510 145 0 145 0 0 510 0
							510 215 0 215 0 0 135 0 135 -575 0 -575 0 0 -135z" />
					<path d="M2365 1883 c-43 -114 -475 -1264 -475 -1268 0 -3 66 -5 148 -5 l148
							0 44 130 45 130 242 0 242 0 19 -52 c10 -29 32 -88 47 -130 l28 -78 143 0 c88
							0 144 4 144 10 0 5 -106 294 -235 641 -129 347 -235 633 -235 635 0 2 -67 4
							-149 4 -133 0 -150 -2 -156 -17z m229 -505 c36 -119 69 -224 72 -232 5 -14
							-12 -16 -146 -16 -127 0 -151 2 -147 14 3 8 36 110 72 228 66 210 74 232 80
							226 2 -2 33 -101 69 -220z" />
					<path d="M3560 1255 l0 -645 420 0 420 0 0 135 0 135 -275 0 -275 0 0 510 0
							510 -145 0 -145 0 0 -645z" />
					<path d="M4820 1255 l0 -645 410 0 410 0 0 135 0 135 -265 0 -265 0 0 120 0
							120 240 0 240 0 0 135 0 135 -240 0 -240 0 0 120 0 120 265 0 265 0 0 135 0
							135 -410 0 -410 0 0 -645z" />
					<path d="M6421 1885 c-92 -21 -162 -58 -214 -114 -57 -61 -82 -111 -97 -190
							-15 -84 -5 -163 31 -238 45 -94 124 -147 292 -197 48 -14 124 -37 170 -51 142
							-42 192 -92 165 -163 -35 -96 -217 -111 -383 -32 -34 16 -82 45 -107 65 -26
							19 -50 35 -55 34 -11 -1 -163 -186 -163 -198 0 -18 98 -89 173 -126 113 -55
							212 -77 352 -78 89 -1 136 4 180 17 206 62 306 202 292 408 -12 176 -115 272
							-368 343 -194 55 -247 75 -274 106 -48 58 -23 135 52 160 85 28 211 -1 345
							-78 31 -18 59 -33 61 -33 3 0 38 48 78 107 l72 106 -29 24 c-44 36 -181 101
							-249 118 -89 23 -247 28 -324 10z" />
					<path d="M7480 1256 l0 -646 145 0 145 0 0 200 0 200 158 0 c222 0 331 32 430
							124 201 188 166 557 -65 693 -105 61 -139 66 -490 71 l-323 4 0 -646z m616
							356 c58 -29 91 -73 100 -128 12 -85 -24 -157 -99 -197 -36 -19 -61 -22 -184
							-25 l-143 -4 0 192 0 193 143 -5 c120 -4 148 -8 183 -26z" />
					<path d="M8910 1255 l0 -645 145 0 145 0 0 645 0 645 -145 0 -145 0 0 -645z" />
					<path d="M9620 1255 l0 -645 145 0 145 0 2 374 3 374 300 -374 300 -374 133 0
							132 0 0 645 0 645 -150 0 -150 0 -2 -376 -3 -375 -305 373 -306 373 -122 3
							-122 3 0 -646z" />
				</g>
			</svg>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					top: 0,
					right: 12,
					height: '56px',
					color: 'var(--footer-font-color)',
					fontSize: '16px',
					overflow: 'visible'
				}}
			>
				v {process.env.REACT_APP_VERSION}
			</div>
		</div>
	);
};

export default Footer;
