import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import ApplicationState from '../../types/store';
import {jsonToSearchParams} from '../../utils';
import {CHANGE_PAGE} from '../../store/actions/auth';

function PageNavigator() {
	let {pathname} = useLocation();

	const {page, urlOutputArguments} = useSelector(
		(state: ApplicationState) => state.appConfigData
	);

	const url = `/${page}/?${jsonToSearchParams(urlOutputArguments)}`;

	if (page && (pathname !== `/${page}` || urlOutputArguments)) {
		return <Redirect to={url} />;
	}

	return <React.Fragment></React.Fragment>;
}

export default PageNavigator;
