import { Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { QuestionEvent } from './SkillsAssessmentState';
import shuffleArray from './ShuffleArray';

interface PROPS {
    questionEvent: QuestionEvent,
    selected?: number | null,
    onAnswerSelected: (eventId: string, answer: any) => void
}
const LongAnswers = (props: PROPS) => {
    const questionEvent = props.questionEvent;
    const selected = props.selected;

    const answers = questionEvent.answers;

    const [randomizedAnswers, setRandomizedAnswers] = useState([]);

    useEffect(() => {
        setRandomizedAnswers(shuffleArray(answers));
    }, []);

    const handleAnswerSelected = (eventId: string, answer: number) => {
        if (answers && randomizedAnswers) {
            const randomAnswer = randomizedAnswers[answer];

            if (randomAnswer) {
                const realAnswer = answers.find(item => item === randomAnswer);

                if (realAnswer && props.onAnswerSelected != null) {
                    props.onAnswerSelected(eventId, answers.indexOf(realAnswer));
                }
            }
        }
    }

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden', margin: 'auto' }}>
            <Typography variant={'h6'} style={{ fontSize: '2rem' }}>{questionEvent.question}</Typography>

            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden' }}>
                <RadioGroup value={'' + selected} style={{ width: '100%' }}>
                    {randomizedAnswers.map((answer, idx) => {
                        return (
                            <div key={idx} id={'survey-answer'} style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', cursor: 'pointer' }} onClick={() => handleAnswerSelected(questionEvent.eventId, idx)}>
                                <div style={{ flex: '1 1 auto' }}>
                                    <Typography>{answer.answer}</Typography>
                                </div>

                                <div style={{ flex: '0 0auto' }}>
                                    <Radio checked={selected === answers.indexOf(randomizedAnswers[idx])} value={idx} />
                                </div>
                            </div>
                        );
                    })}
                </RadioGroup>
            </div>
        </div>
    );
};

export default LongAnswers;
