import {useCallback} from 'react';
import {
	ADD_OUTPUT_SEARCH_PARAMS,
	createRequest,
	FINISHING_AUTH,
	HANDOFF_TOKEN,
	NO_PERMISSIONS_ERROR,
	ADD_INPUT_SEARCH_PARAMS,
} from '../store/actions/auth';
import {useDispatch, useSelector} from 'react-redux';
import HandoffToken from '../types/brainCloud/HandoffToken';
import AppConfigData from '../types/brainCloud/AppConfigData';

const useGeneratePin = () => {
	const dispatch = useDispatch();

	const urlInputArguments = useSelector(
		(state: {appConfigData: AppConfigData}) => {
			return state.appConfigData.urlInputArguments;
		}
	);

	const pin = useCallback(
		(params: any = {}) => {
			// console.log("Generating PIN...", localStorage.getItem(NO_PERMISSIONS_ERROR));
			localStorage.setItem('generatingPin', 'true');
			const requestedTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];

			var scriptName = 'createHandoffToken';

			let scriptData: any = {};
			if (requestedTrainingModules.length > 0) {
				scriptData = {
					trainingModules: requestedTrainingModules,
				};
			}

			const request = createRequest(scriptName, scriptData);

			return fetch(request)
				.then((response) => {
					if (response.status !== 200) {
						return Promise.resolve();;
					}

					return response.json();
				})
				.then((json) => {
					if (
						!json ||
						!json.data ||
						!json.data.response ||
						!json.data.response.securityToken
					) {
						const isVr = localStorage.getItem('isVr') === 'true';
						const requestedTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];
						const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined' ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null;

						localStorage.clear();
						localStorage.setItem('isVr', JSON.stringify(isVr));
						localStorage.setItem('requestedTrainingModules', requestedTrainingModules.join(','));

						if (noPermissionsError) localStorage.setItem(NO_PERMISSIONS_ERROR, noPermissionsError);

						if (isVr) {
							if (requestedTrainingModules.length > 0) {
								window.location.href = window.location.origin + '/vr/?trainingModules=' + requestedTrainingModules.join(',');
							} else {
								window.location.href = window.location.origin + '/vr/';
							}
						} else {
							if (requestedTrainingModules.length > 0) {
								window.location.href = window.location.origin + '/?trainingModules=' + requestedTrainingModules.join(',');
							} else {
								window.location.href = window.location.origin + '/';
							}
						}

						localStorage.setItem('generatingPin', 'false');
						dispatch({
							type: NO_PERMISSIONS_ERROR,
							payload: localStorage.getItem(NO_PERMISSIONS_ERROR)
						});

						return;
					}

					var payload: HandoffToken = json.data.response;

					localStorage.setItem(HANDOFF_TOKEN, JSON.stringify(payload));

					localStorage.setItem('securityToken', payload.securityToken);
					localStorage.setItem(
						'expireSeconds',
						payload.expireSeconds.toString()
					);
					localStorage.setItem('currentDate', payload.currentDate.toString());
					localStorage.setItem('expireDate', payload.expireDate.toString());

					localStorage.setItem(NO_PERMISSIONS_ERROR, undefined);

					if (json.data.response.accessibleTraining) {
						const trainings = json.data.response.accessibleTraining;
						let hasFoundTraining = false;
						let count = 0;

						for (let key in trainings) {
							if (trainings[key] === true) {
								hasFoundTraining = true;
							}

							count++;
						}

						if (!hasFoundTraining && count > 0) {
							// Determine the appropiate error message to display to the user.
							let errorMessage = null;
							if (scriptData.trainingModules && scriptData.trainingModules.length > 0 && json.data.response.accessibleTrainingErrorMessage) {
								for (let idx = 0; idx < scriptData.trainingModules.length; idx++) {
									if (errorMessage) break;

									if (scriptData.trainingModules[idx] && json.data.response.accessibleTrainingErrorMessage[scriptData.trainingModules[idx]]) {
										errorMessage = json.data.response.accessibleTrainingErrorMessage[scriptData.trainingModules[idx]];
									}
								}
							}

							dispatch({
								type: NO_PERMISSIONS_ERROR,
								payload: errorMessage
							});

							localStorage.setItem('generatingPin', 'false');
	
							return;
						}
					}

					// We are only considered fully authenticated when we have generated in the Pin.
					dispatch({
						type: FINISHING_AUTH,
						payload: payload,
					});

					if (json.data.response.accessibleTraining) {
						let urlInputSearchParams: any = {};
						try {
							urlInputSearchParams = JSON.parse(localStorage.getItem(ADD_INPUT_SEARCH_PARAMS));
						} catch (error) {}

						dispatch({
							type: ADD_OUTPUT_SEARCH_PARAMS,
							payload: { ...json.data.response.accessibleTraining, ...urlInputSearchParams },
						});
					}

					dispatch({
						type: HANDOFF_TOKEN,
						payload: payload,
					});

					localStorage.setItem('generatingPin', 'false');
					dispatch({
						type: NO_PERMISSIONS_ERROR,
						payload: localStorage.getItem(NO_PERMISSIONS_ERROR)
					});

					return;
				});
		},
		[urlInputArguments]
	);

	return pin;
};

export default useGeneratePin;
