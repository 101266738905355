import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

const PageError = (props) => {
	return (
		<div
			style={{
				left: 0,
				top: 0,
				overflowX: 'hidden',
				position: 'absolute',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				backgroundImage: 'linear-gradient(var(--error-background-gradient-start), var(--error-background-gradient-end))',
				color: 'var(--error-font-color)'
			}}
		>
			<CssBaseline />

			<div style={{flex: 2}} />

			<div
				style={{
					margin: 'auto',
					fontSize: '18px',
				}}
			>
				{props.error.message}
			</div>
			<div style={{flex: 3}} />
		</div>
	);
};

export default PageError;
