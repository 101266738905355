export const serverBasePath = `${process.env.REACT_APP_PROXY_URL}`;

require('dotenv').config();

const location = window.location.hostname.split('.')[0];
const envProject = process.env.REACT_APP_PROJECT;

export const project =
  location === 'localhost' ? envProject : location;

export const BC_URI = {
  getPublicAppConfig: serverBasePath + '/getAppSystemConfig',
  getOrgConfig: serverBasePath + '/v1/runwayOrgConfig',
  postLogin: serverBasePath + '/authenticationTS/AUTHENTICATE',
  postScript: serverBasePath + '/script/run',
  termsOfServiceStatus: serverBasePath + '/v1/termsOfService',
};

const envRequestingIP = process.env.REACT_APP_REQUESTING_IP;

export const requestingIP = envRequestingIP ? envRequestingIP : '10.0.0.42';


export function jsonToSearchParams(params: any) {
  let searchParams = '';

  for(let key in params) {
    if(params[key] !== undefined) {
      searchParams += `${key}=${params[key]}&`;
    }
  }

  if(searchParams !== '')
    searchParams = searchParams.slice(0, -1);

  return searchParams;
}
