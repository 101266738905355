import {Offline} from 'react-detect-offline';
import React from 'react';
import {useSelector} from 'react-redux';
import OuterBox from '../../components/layout/OuterBox';
import InnerBox from '../../components/layout/InnerBox';
import ErrorText from '../../components/text/ErrorText';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import ErrorIcon from '@material-ui/icons/Error';
import { NO_PERMISSIONS_ERROR, HANDOFF_TOKEN } from 'store/actions/auth';

const LoginPage = props => {
	const {hasPermissionError, errors} = useSelector((state: {appConfigData: AppConfigData}) => {
		return {
			hasPermissionError: (localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined') ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null,
			errors: state.appConfigData.errors
		};
	});
	const errorMessage = hasPermissionError ? hasPermissionError : errors && errors.length > 0 ? errors[0].message : null;

	if (errorMessage) {
		// console.log("Error Message", hasPermissionError, JSON.stringify(errors));

		localStorage.removeItem(HANDOFF_TOKEN);
		localStorage.removeItem('securityToken');
		localStorage.removeItem('expireSeconds');
		localStorage.removeItem('currentDate');
		localStorage.removeItem('expireDate');
		localStorage.removeItem('token');
	}
	
	return (
		<OuterBox>
			<InnerBox>
				<div style={{ flex: '1 1 auto', width: '100%', paddingBottom: '56px' }}>
					{errorMessage && (
						<div
							style={{
								backgroundImage: 'linear-gradient(var(--error-background-gradient-start), var(--error-background-gradient-end))',
								color: 'var(--error-font-color)',
								width: '100vw',
								padding: '18px'
							}}
						>
							<div
								style={{
									color: 'var(--error-font-color)',
									fontWeight: 'bold',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									fontFamily: 'Helvetica Now Bold',
									textAlign: 'center',
									justifyContent: 'center'
								}}
							>
								<ErrorIcon style={{marginRight: '4px'}} /> {errorMessage}
							</div>
						</div>
					)}

					<Offline>
						<div style={{
							width: '100vw',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							textAlign: 'center'
						}}>
							<ErrorText>No connection. Please check your internet.</ErrorText>
						</div>
					</Offline>
				</div>
			</InnerBox>
		</OuterBox>
	);
};

export default LoginPage;
