import ImageWithText from './ImageWithText';
import React, { useEffect, useState } from 'react';
import { QuestionEvent } from './SkillsAssessmentState';
import shuffleArray from './ShuffleArray';
import AutoSizer from 'react-virtualized-auto-sizer';

interface PROPS {
    questionEvent: QuestionEvent,
    selected?: number | null,
    onAnswerSelected: (eventId: string, answer: any) => void
}
const QuestionImageAndAnswerImages = (props: PROPS) => {
    const questionEvent = props.questionEvent;
    const selected = props.selected;

    const answers = questionEvent.answers;

    const [randomizedAnswers, setRandomizedAnswers] = useState([]);

    useEffect(() => {
        setRandomizedAnswers(shuffleArray(answers));
    }, []);

    const handleAnswerSelected = (eventId: string, answer: number) => {
        if (answers && randomizedAnswers) {
            const randomAnswer = randomizedAnswers[answer];

            if (randomAnswer) {
                const realAnswer = answers.find(item => item === randomAnswer);

                if (realAnswer && props.onAnswerSelected != null) {
                    props.onAnswerSelected(eventId, answers.indexOf(realAnswer));
                }
            }
        }
    }

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', width: '100%', height: '100%', overflow: 'hidden' }}>
            <AutoSizer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
                {({ height, width }) => {
                    const padding = 10;

                    const questionSrcWidth = 115;
                    const questionSrcHeight = 150;
                    const answerSrcWidth = 140;
                    const answerSrcHeight = 150;

                    const questionWidthRatio = questionSrcWidth / questionSrcHeight;
                    const answerWidthRatio = answerSrcWidth / answerSrcHeight;

                    let targetHeight = height - padding;
                    let targetWidth = width - padding;

                    let questionHeight = targetHeight;
                    let questionWidth = questionHeight * questionWidthRatio;

                    let answerHeight = (targetHeight / 2) - (padding / 2);
                    let answerWidth = answerHeight  * answerWidthRatio;

                    let realHeight = (answerHeight * 2) + padding;
                    let realWidth = (answerWidth * 2) + questionWidth + (padding * 2);

                    if (realWidth > targetWidth || realHeight > targetHeight) {
                        const reductionRatio = realHeight > targetHeight ? targetHeight / realHeight : targetWidth / realWidth;

                        questionHeight = questionHeight * reductionRatio;
                        questionWidth = questionWidth * reductionRatio;
                        answerHeight = answerHeight * reductionRatio;
                        answerWidth = answerWidth * reductionRatio;

                        realHeight = (answerHeight * 2) + padding;
                        realWidth = (answerWidth * 2) + questionWidth + (padding * 2);
                    }

                    return (
                        <div id={'wrapper'} style={{ height: realHeight, width: realWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden', margin: 'auto' }}>
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingRight: padding }}>
                                <ImageWithText
                                    src={questionEvent.questionImageUrl}
                                    text={questionEvent.question}
                                    disabled={true}
                                    width={questionWidth} height={questionHeight}
                                    fontSize={window.innerHeight * 0.02}
                                />
                            </div>

                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', paddingBottom: padding }}>
                                    {randomizedAnswers && randomizedAnswers.length > 0 &&
                                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingRight: padding }}>
                                            <ImageWithText
                                                src={randomizedAnswers[0].answerImageUrl}
                                                text={randomizedAnswers[0].answer}
                                                selected={selected === answers.indexOf(randomizedAnswers[0])}
                                                onClick={() => handleAnswerSelected(questionEvent.eventId, 0)}
                                                width={answerWidth} height={answerHeight}
                                                fontSize={window.innerHeight * 0.014}
                                            />
                                        </div>
                                    }

                                    {randomizedAnswers && randomizedAnswers.length > 1 &&
                                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                            <ImageWithText
                                                src={randomizedAnswers[1].answerImageUrl}
                                                text={randomizedAnswers[1].answer}
                                                selected={selected === answers.indexOf(randomizedAnswers[1])}
                                                onClick={() => handleAnswerSelected(questionEvent.eventId, 1)}
                                                width={answerWidth} height={answerHeight}
                                                fontSize={window.innerHeight * 0.014}
                                            />
                                        </div>
                                    }
                                </div>

                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
                                    {randomizedAnswers && randomizedAnswers.length > 2 &&
                                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingRight: padding }}>
                                            <ImageWithText
                                                src={randomizedAnswers[2].answerImageUrl}
                                                text={randomizedAnswers[2].answer}
                                                selected={selected === answers.indexOf(randomizedAnswers[2])}
                                                onClick={() => handleAnswerSelected(questionEvent.eventId, 2)}
                                                width={answerWidth} height={answerHeight}
                                                fontSize={window.innerHeight * 0.014}
                                            />
                                        </div>
                                    }

                                    {randomizedAnswers && randomizedAnswers.length > 3 &&
                                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                            <ImageWithText
                                                src={randomizedAnswers[3].answerImageUrl}
                                                text={randomizedAnswers[3].answer}
                                                selected={selected === answers.indexOf(randomizedAnswers[3])}
                                                onClick={() => handleAnswerSelected(questionEvent.eventId, 3)}
                                                width={answerWidth} height={answerHeight}
                                                fontSize={window.innerHeight * 0.014}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    );
                }}
            </AutoSizer>
        </div>
    );
};

export default QuestionImageAndAnswerImages;
