import React, { CSSProperties } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles } from '@material-ui/core';

interface PROPS {
    src: string | null,
    text?: string | null,
    width?: number | null,
    height?: number | null,
    style?: CSSProperties | null,
    disabled?: boolean | null,
    selected?: boolean | null,
    fontSize?: string | number,
    onClick?: () => void | null,
    classes?: any
}

// Styling for this component.
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
        borderStyle: 'solid',
        borderWidth: (props: PROPS) => props.selected ? '6px' : '3px',
        borderColor: (props: PROPS) => props.selected ? 'var(--tos-continue-button-hover-background-color)' : 'lightgrey',
        cursor: (props: PROPS) => props.disabled || props.selected ? 'normal' : 'pointer',
        '&:hover': {
            borderColor: (props: PROPS) => props.disabled ? 'lightgrey' : props.selected ? 'var(--tos-continue-button-hover-background-color)' : 'var(--tos-continue-button-background-color)'
        }
    },
    image: {
        width: '100%',
        height: '100%',
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: '23%',
        maxHeight: '23%',
        backgroundColor: 'transparent',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        '& .MuiTypography-root': {
            lineHeight: 'unset',
            fontSize: (props: PROPS) => props.fontSize != null ? props.fontSize : 'inherit',
            textAlign: 'justify',
            margin: 0,
            maxWidth: 'unset',
            width: '100%',
            color: '#FFFFFF'
        }
    },
    disabled: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'transparent'
    }
});

const ImageWithText = (props: PROPS) => {
    const { classes, src, text, width, height, disabled, selected, fontSize, onClick } = props;

    const handleClick = () => {
        if (!disabled && !selected && onClick != null) {
            onClick();
        }
    }

    return (
        <div className={classes.root} style={{ width: width, height: height }} onClick={handleClick}>
            <div className={classes.image}>
                <img src={src} alt={'image-with-text'} />
            </div>

            {text != null &&
                <div className={classes.text}>
                    <Typography>{text}</Typography>
                </div>
            }

            {disabled &&
                <div className={classes.disabled}>
                </div>
            }
        </div>
    );
};

export default withStyles(styles)(ImageWithText);
