import {mapStateToProps} from 'types/store';
import {mapDispatchToProps} from 'store';
import React, {useEffect, useState} from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import {compose} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import useLogout from '../../hooks/useLogout';
import {CHANGE_PAGE, createRequest} from '../../store/actions/auth';
import { SvgIcon, Typography } from '@material-ui/core';
import SystemMessage from 'sections/SystemMessage';

const PrimarySearchAppBar = (props: any) => {
	const logout = useLogout();

	const dispatch = useDispatch();

	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	const [
		mobileMoreAnchorEl,
		setMobileMoreAnchorEl,
	] = React.useState<null | HTMLElement>(null);

	const [menuItems, setMenuItems] = useState({});
	const [skillsAssessmentSurveyAccessible, setSkillsAssessmentSurveyAccessible] = useState(false);

	useEffect(() => {
		var list: any[] = [];
		if (
			window.location.pathname.includes('/pin') ||
			window.location.pathname.includes('/welcome') ||
			window.location.pathname.includes('/skillsAssessmentSurvey')
		) {
			if (
				appConfigData &&
				appConfigData.sessionToken &&
				(appConfigData.sessionToken.role === 'admin' ||
					appConfigData.sessionToken.role === 'manager')
			) {
				list.push(
					<MenuItem onClick={openRunwayPortal}>Open Runway Portal</MenuItem>
				);
			}

			list.push(<MenuItem onClick={logout}>Logout</MenuItem>);

			setMenuItems(list);
		}
	}, [window.location.href, appConfigData]);

	useEffect(() => {
		if (localStorage.getItem('isVr') === 'true' || window.location.pathname.includes('skillsAssessmentSurvey') || localStorage.getItem('isSkillsAssessmentSurvey') === 'true') return;

		if (window.location.pathname.includes('forgot-password')) return;

		setTimeout(() => {
			const statusRequest = createRequest('getStatusSkillsAssessmentSurvey', {});
			fetch(statusRequest).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}

				return Promise.reject('HTTP Status [' + response.status + ']: ' + response.statusText);
			}).then(json => {
				console.log("Skills Assessment Survey Status", json);
	
				if (json && json.data && json.data.response && json.data.response.hasAccess) {
					setSkillsAssessmentSurveyAccessible(true);
				}
			}).catch(error => {
				console.log("Failed To Fetch Skills Assessment Survey Status", error);

				if (error && (error.includes('HTTP Status [401]') || error.includes('HTTP Status [403]'))) {
					localStorage.clear();
	
					window.location.pathname = '/login';
				}
			});
		}, 100);
	}, []);

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const openRunwayPortal = (event: React.MouseEvent<HTMLElement>) => {
		var runwayUrl =
			window.location.origin.replace('passport', 'runway') + '/#/login';
		window.open(runwayUrl);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = () => {
		var list: any[] = [];
		if (
			window.location.pathname.includes('/pin') ||
			window.location.pathname.includes('/welcome') ||
			window.location.pathname.includes('/skillsAssessmentSurvey')
		) {
			if (
				appConfigData &&
				appConfigData.sessionToken &&
				(appConfigData.sessionToken.role === 'admin' ||
					appConfigData.sessionToken.role === 'manager')
			) {
				list.push(
					<MenuItem key={'runway'} onClick={openRunwayPortal}>Open Runway Portal</MenuItem>
				);
			}

			if (!props.isVr && skillsAssessmentSurveyAccessible && !window.location.pathname.includes('/skillsAssessmentSurvey')) {
				list.push(<MenuItem key={'skillsAssessmentSurvey'} onClick={() => {
					dispatch({
						type: CHANGE_PAGE,
						payload: 'skillsAssessmentSurvey',
					});
				}}>Skills Assessment</MenuItem>);
			}

			list.push(<MenuItem key={'logout'} onClick={logout}>Logout</MenuItem>);
		}

		return (
			<Menu
				anchorEl={mobileMoreAnchorEl}
				anchorOrigin={{vertical: 'top', horizontal: 'right'}}
				id={mobileMenuId}
				keepMounted
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
				open={isMobileMenuOpen}
				onClose={handleMobileMenuClose}
			>
				{list}
			</Menu>
		);
	};

	const HomeIcon = (props) => {
		return (
		  <SvgIcon {...props} viewBox={'0 0 40 40'}>
			<path d="M39.26,15.11,21.27.45a2,2,0,0,0-2.54,0L.74,15.11a2,2,0,1,0,2.54,3.12l1.57-1.29V32.19c0,4.38,2.64,7.81,6,7.81H29.14c3.37,0,6-3.43,6-7.81V16.94l1.58,1.29a2,2,0,0,0,1.27.45,2,2,0,0,0,1.56-.74A2,2,0,0,0,39.26,15.11ZM23.16,36H16.84V24.71a1.09,1.09,0,0,1,0-.28h6.26a1.09,1.09,0,0,1,0,.28Zm8-3.79c0,2.32-1.18,3.79-2,3.79h-2V24.71c0-2.41-1.57-4.3-3.58-4.3H16.4c-2,0-3.58,1.89-3.58,4.3V36h-2c-.81,0-2-1.47-2-3.79V13.67L20,4.6l11.13,9.07Z"/>
		  </SvgIcon>
		);
	  }

	return (
		<div style={{ position: 'relative' }}>
			<SystemMessage includeTopMargin={false} />

			<div style={{ height: 0 }}>
			<AppBar style={{ position: 'relative' }}>
				<Toolbar>
					{(window.location.pathname.includes('/pin') || window.location.pathname.includes('/forgot-password') || window.location.pathname.includes('/skillsAssessmentSurvey')) && (
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', left: 20, top: 6 }} onClick={() => {
							if (window.location.pathname.includes('/pin') || window.location.pathname.includes('/skillsAssessmentSurvey'))
								dispatch({
									type: CHANGE_PAGE,
									payload: 'welcome',
								});
							//								window.location.href = window.location.origin + '/welcome';

							if (window.location.pathname.includes('/forgot-password'))
								dispatch({
									type: CHANGE_PAGE,
									payload: 'login',
								});
							//								window.location.href = window.location.origin + '/login';
						}}>
							{/* <ArrowBackIosIcon fontSize="large" style={{ color: 'var(--navigation-icon-color)' }} /> */}

							<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
								<HomeIcon style={{ color: 'var(--navigation-icon-color)' }} />
								<Typography style={{ marginLeft: 10, color: 'var(--navigation-icon-color)', textAlign: 'left', minWidth: 'unset', maxWidth: 'unset' }}>Home</Typography>
							</div>
						</div>
					)}

					<div style={{flex: 1}} />

					{(window.location.pathname.includes('/pin') || window.location.pathname.includes('/forgot-password') || window.location.pathname.includes('/skillsAssessmentSurvey')) && (
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', right: 20, top: 6 }} onClick={() => {
							if (window.location.pathname.includes('/pin') || window.location.pathname.includes('/skillsAssessmentSurvey'))
								dispatch({
									type: CHANGE_PAGE,
									payload: 'welcome',
								});
							//								window.location.href = window.location.origin + '/welcome';

							if (window.location.pathname.includes('/forgot-password'))
								dispatch({
									type: CHANGE_PAGE,
									payload: 'login',
								});
							//								window.location.href = window.location.origin + '/login';
						}}>
							{/* <ArrowBackIosIcon fontSize="large" style={{ display: 'none', color: 'var(--navigation-icon-color)' }}/> */}

							<div style={{ display: 'none' }}>
								<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
									<HomeIcon style={{ color: 'var(--navigation-icon-color)' }} />
									<Typography style={{ marginLeft: 10, color: 'var(--navigation-icon-color)', textAlign: 'left', minWidth: 'unset', maxWidth: 'unset' }}>Home</Typography>
								</div>
							</div>
						</div>
					)}

					{!window.location.pathname.includes('/forgot-password') && (
						<IconButton
							aria-label='show more'
							aria-controls={mobileMenuId}
							aria-haspopup='true'
							onClick={handleMobileMenuOpen}
						>
							<MenuIcon style={{fontSize: '32px', margin: 'auto'}} />
						</IconButton>
					)}
				</Toolbar>
			</AppBar>
			</div>

			{renderMobileMenu()}
		</div>
	);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
	PrimarySearchAppBar
);
