import React, { useState } from 'react';
import SkillsAssessmentResult from '../SkillsAssessmentResult';
import YourResults from './YourResults';
import YourSkillsMap from "./YourSkillsMap";
import YourSkillsGraph from "./YourSkillsGraph";

interface PROPS {
    skillsAssessmentResult: SkillsAssessmentResult
}
const Results = (props: PROPS) => {
    const getFilteredSkillsAssessmentResult = (skillsAssessmentResult: any, showNonCriticalSkills: boolean) => {
        const result = Object.assign({}, skillsAssessmentResult);

        const skillCategoryKeys = Object.keys(skillsAssessmentResult.skills);
        const filteredSkillCategories = {};

        for (let x = 0; x < skillCategoryKeys.length; x++) {
            const skillCategoryKey = skillCategoryKeys[x];
            const skillCategory = skillsAssessmentResult.skills[skillCategoryKey];
    
            const filteredSkills = [];
    
            for (let y = 0; y < skillCategory.items.length; y++) {
                if (showNonCriticalSkills) {
                    filteredSkills.push(skillCategory.items[y]);
                } else {
                    if (skillCategory.items[y].criticalSkill) {
                        filteredSkills.push(skillCategory.items[y]);
                    }
                }
            }
    
            if (filteredSkills.length > 0) {
                filteredSkillCategories[skillCategoryKey] = Object.assign({}, props.skillsAssessmentResult.skills[skillCategoryKey]);
                filteredSkillCategories[skillCategoryKey].items = filteredSkills;
            }
        }

        result.skills = filteredSkillCategories;

        return result;
    }

    const skillCategories = Object.keys(props.skillsAssessmentResult.skills);
    let defaultSkillCategory = skillCategories.length > 0 ? skillCategories[0] : null;
    for (let x = 0; x < skillCategories.length; x++) {
        if (props.skillsAssessmentResult.skills[skillCategories[x]].sortOrder === 1) {
            defaultSkillCategory = skillCategories[x];
            break;
        }
    }

    const [selectedSkillCategory, setSelectedSkillCategory] = useState(defaultSkillCategory);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [showNonCriticalSkills, setShowNonCriticalSkills] = useState(true);

    const handleSkillCateogrySelected = (skillCateogry: string) => {
        setSelectedSkillCategory(skillCateogry);
        setSelectedSkill(null);
    }

    const handleSkillSelected = (skill: any) => {
        setSelectedSkill(skill);
    }

    const handleShowNonCriticalSkillsChanged = (selected: boolean) => {
        const currentResult = getFilteredSkillsAssessmentResult(props.skillsAssessmentResult, selected);

        let newDefaultSkillCategory = selectedSkillCategory;

        if (!currentResult.skills[newDefaultSkillCategory]) {
            const skillCategories = Object.keys(currentResult.skills);
            
            skillCategories.sort((a: string, b: string) => {
                if (currentResult.skills[a].sortOrder === currentResult.skills[b].sortOrder) return 0;
                if (currentResult.skills[a].sortOrder < currentResult.skills[b].sortOrder) return -1;
                if (currentResult.skills[a].sortOrder > currentResult.skills[b].sortOrder) return 1;
            });

            if (skillCategories.length > 0) newDefaultSkillCategory = skillCategories[0];
        }

        setShowNonCriticalSkills(selected);
        setSelectedSkillCategory(newDefaultSkillCategory);
        setSelectedSkill(null);
    }

    const filteredResults = getFilteredSkillsAssessmentResult(props.skillsAssessmentResult, showNonCriticalSkills);

    return (
        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', width: '100%', overflow: 'auto' }}>
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', margin: 'auto' }}>
                <YourResults />
                <YourSkillsMap skillsAssessmentResult={filteredResults} selectedSkillCategory={selectedSkillCategory} onSkillCateogrySelected={handleSkillCateogrySelected} selectedSkill={selectedSkill} onSkillSelected={handleSkillSelected} showNonCriticalSkills={showNonCriticalSkills} onShowNonCriticalSkillsChanged={handleShowNonCriticalSkillsChanged} />
                <YourSkillsGraph skillsAssessmentResult={filteredResults} selectedSkillCategory={selectedSkillCategory} onSkillCateogrySelected={handleSkillCateogrySelected} selectedSkill={selectedSkill} onSkillSelected={handleSkillSelected} showNonCriticalSkills={showNonCriticalSkills} />
            </div>
        </div>
    );
};

export default Results;
