import React from 'react';

const OuterBox = props => {
	const {children} = props;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%'
			}}
		>
			{children}
		</div>
	);
};

export default OuterBox;
