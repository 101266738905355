import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import {Field, Form} from 'react-final-form';
import OuterBox from '../../../components/layout/OuterBox';
import InnerBox from '../../../components/layout/InnerBox';
import LoginButton from './LoginButton';
import FieldLayout from './FieldLayout';
import useLogin from '../../../hooks/useLogin';
import AppConfigData from '../../../types/brainCloud/AppConfigData';
import {useDispatch, useSelector} from 'react-redux';
import ErrorSymbol from '../../../components/text/ErrorSymbol';
import {CHANGE_PAGE} from '../../../store/actions/auth';

const Email = (props) => {
	const login = useLogin();

	const dispatch = useDispatch();

	const isAuthing = useSelector((state: {appConfigData: AppConfigData}) => {
		return state?.appConfigData?.appState?.isAuthing;
	});

	let handleFormSubmit = () => {};
	const onFormSubmit = async (values) => {
		const externalId = values['externalId'].trim();
		const token = values['token'];

		login({username: externalId, password: token});
	};

	const myInput = useRef();

	useEffect(() => {
		if (myInput != null) {
			const inputElement = (myInput.current as any);
			if (inputElement) {
				inputElement.focus();
			}
		}
	});

	return (
		<OuterBox>
			<InnerBox>
				<Form
					subscription={{submitFailed: true}}
					onSubmit={onFormSubmit}
					validate={(values) => {
						let errors: any = {};
						if (!values.externalId) {
							errors.externalId = 'Enter your email';
						}
						if (!values.token) {
							errors.token = 'Enter your password';
						}
						return errors;
					}}
				>
					{({handleSubmit, submitting}) => {
						handleFormSubmit = handleSubmit;

						return (
							<form onSubmit={() => handleSubmit()}>
								<Field name='externalId'>
									{({input, meta}) => (
										<FieldLayout>
											Email Address
											<TextField
												id='email'
												variant='outlined'
												fullWidth
												{...input}
												error={meta.error && meta.submitFailed}
												onFocus={(event) => {
													const caretPosition = input && input.value && input.value.length > 0 ? input.value.length : 0;
													if (event && event.target && event.target.setSelectionRange) {
														event.target.setSelectionRange(caretPosition, caretPosition);
													}
												}}
												inputRef={myInput}
											/>
											{meta.error && meta.submitFailed && <ErrorSymbol message={meta.error} />}
										</FieldLayout>
									)}
								</Field>

								<Field name='token'>
									{({input, meta}) => (
										<FieldLayout>
											Password
											<TextField
												id='password'
												variant='outlined'
												fullWidth
												type='password'
												{...input}
												error={meta.error && meta.submitFailed}
												onFocus={(event) => {
													const caretPosition = input && input.value && input.value.length > 0 ? input.value.length : 0;
													if (event && event.target && event.target.setSelectionRange) {
														event.target.setSelectionRange(caretPosition, caretPosition);
													}
												}}
											/>
											{meta.error && meta.submitFailed && <ErrorSymbol message={meta.error} />}
										</FieldLayout>
									)}
								</Field>

								<LoginButton
									isSubmitting={isAuthing}
									onClick={(e) => {
										e.preventDefault();

										handleFormSubmit();
									}}
								>
									Log In
								</LoginButton>
							</form>
						);
					}}
				</Form>
				<div style={{width: '100%', display: 'flex'}}>
					<div
						onClick={() => {
							dispatch({type: CHANGE_PAGE, payload: 'forgot-password'});
						}}
						style={{
							flex: 1,
							fontFamily: 'Helvetica Now Bold',
							color: 'var(--page-secondary-font-color)',
							cursor: 'pointer',
							paddingTop: '16px',
							paddingBottom: '16px',
							textAlign: 'center',
						}}
					>
						Forgot Password
					</div>
				</div>
			</InnerBox>
		</OuterBox>
	);
};

export default Email;
