import React, { useEffect, useState } from 'react';
import ImageWithText from './ImageWithText';
import { Button, Typography } from '@material-ui/core';
import { QuestionEvent } from './SkillsAssessmentState';
import shuffleArray from './ShuffleArray';
import AutoSizer from 'react-virtualized-auto-sizer';

interface PROPS {
    questionEvent: QuestionEvent,
    selected?: number | null,
    onAnswerSelected: (eventId: string, answer: any) => void
}
const QuestionImageAndAnswerButtons = (props: PROPS) => {
    const questionEvent = props.questionEvent;
    const selected = props.selected;

    const answers = questionEvent.answers;

    const [randomizedAnswers, setRandomizedAnswers] = useState([]);

    useEffect(() => {
        setRandomizedAnswers(shuffleArray(answers));
    }, []);

    const handleAnswerSelected = (eventId: string, answer: number) => {
        if (answers && randomizedAnswers) {
            const randomAnswer = randomizedAnswers[answer];

            if (randomAnswer) {
                const realAnswer = answers.find(item => item === randomAnswer);

                if (realAnswer && props.onAnswerSelected != null) {
                    props.onAnswerSelected(eventId, answers.indexOf(realAnswer));
                }
            }
        }
    }

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'stretch', width: '100%', height: '100%', overflow: 'hidden' }}>
            <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', width: '100%', height: '100%', overflow: 'hidden' }}>
                <AutoSizer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
                    {({ height, width }) => {
                        const padding = 0;

                        const questionSrcWidth = 300;
                        const questionSrcHeight = 136;

                        const questionWidthRatio = questionSrcWidth / questionSrcHeight;

                        let targetHeight = height - padding;
                        let targetWidth = width - padding;

                        let questionHeight = targetHeight;
                        let questionWidth = questionHeight * questionWidthRatio;

                        let realHeight = questionHeight + padding;
                        let realWidth = questionWidth + (padding * 2);

                        if (realWidth > targetWidth || realHeight > targetHeight) {
                            const reductionRatio = realHeight > targetHeight ? targetHeight / realHeight : targetWidth / realWidth;

                            questionHeight = questionHeight * reductionRatio;
                            questionWidth = questionWidth * reductionRatio;

                            realHeight = questionHeight + padding;
                            realWidth = questionWidth + (padding * 2);
                        }

                        return (
                            <div id={'wrapper'} style={{ height: realHeight, width: realWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                                    <ImageWithText
                                        src={questionEvent.questionImageUrl}
                                        text={questionEvent.question}
                                        disabled={true}
                                        width={questionWidth} height={questionHeight}
                                    />
                                </div>
                            </div>
                        );
                    }}
                </AutoSizer>
            </div>

            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', overflow: 'hidden', margin: 'auto' }}>
                {randomizedAnswers.map((answer, idx) => {
                    return (
                        <div key={idx} style={{ flex: '0 0 auto', position: 'relative' }}>
                            <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', margin: 10, width: '150px' }} onClick={() => handleAnswerSelected(questionEvent.eventId, idx)}>{answer.answer}</Button>

                            {selected === answers.indexOf(randomizedAnswers[idx]) &&
                                <div className={'hover-layer-selected'} style={{ margin: 10, border: '1px solid transparent', backgroundColor: 'var(--tos-continue-button-hover-background-color)', borderRadius: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography style={{ fontSize: '0.875rem' }}>{answer.answer}</Typography>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default QuestionImageAndAnswerButtons;
