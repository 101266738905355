import {useCallback} from 'react';
import {BC_URI, project} from '../utils';
import {
	ADD_AUTH_PAYLOAD,
	AUTH_ERROR,
	CLEAR_ERRORS,
	STARTING_AUTH,
	NO_PERMISSIONS_ERROR,
	createRequest,
} from '../store/actions/auth';
import {useDispatch} from 'react-redux';
import SessionToken from 'types/brainCloud/SessionToken';
import decodeJwt from 'jwt-decode';
import analytics from "analytics/analytics";

const useLogin = () => {
	const dispatch = useDispatch();

	const shareAccessToken = async () => {
		const externalId = localStorage.getItem('externalId');
		const access_token = localStorage.getItem('access_token');

		if (externalId && access_token) {
			const request = createRequest('user/refreshToken', {
				externalId,
				access_token,
			});

			try {
				const response = await fetch(request);
				if (response.status !== 200) {
					console.error('Unable to share access token: HTTP Status ' + response.status);
				}
			} catch (error) {
				console.error('Unable to share access token: ', error);
			}
		}
	}

	const login = useCallback((params: any = {}) => {
		dispatch({
			type: CLEAR_ERRORS,
		});

		dispatch({
			type: STARTING_AUTH,
			payload: {},
		});

		const request = new Request(BC_URI.postLogin, {
			method: 'POST',
			body: JSON.stringify({
				username: params.username,
				password: params.password,
			}),
			headers: new Headers({
				'Content-Type': 'application/json',
				'X-PROJECT': project,
			}),
		});

		fetch(request)
			.then((response) => {
				return response.json();
			})
			.then(async (json) => {
				if (json && json.error) {
					try {
						var response = JSON.parse(json.error.message);

						var error = {
							code: response.reason_code,
							message: 'Contact admin.',
						};

						if (
							response.reason_code === 40206 ||
							response.reason_code === 40208
						) {
							error.message = 'Profile not found. Please contact your admin.';
						}

						if (response.reason_code === 40307) {
							error.message = response?.userMessage ? response?.userMessage : 'Invalid Username or Password';
						}

						dispatch({
							type: AUTH_ERROR,
							payload: error,
						});
					} catch (e) {
						console.log(
							`Couldn't parse error message ${JSON.stringify(json)}.`
						);
					}

					return;
				}

				if (!json || !json.token) {
					return;
				}

				//TODO[JON] Display with the new auth response data in the URL

				const summaryFriendData = json?.summaryFriendData;

				const decodedToken = decodeJwt(json.token);

				localStorage.setItem('token', json.token);
				localStorage.setItem('exp', (decodedToken.exp * 1000).toString());

				// Process Terms Of Service Logic.
				let packetId = 1;
				localStorage.setItem('packetId', packetId.toString());

				const userId = decodedToken?.id;

				/* Analytics: identify a visitor */
				analytics.identify(userId, {
					sessionId: decodedToken?.sessionId,
					organizationId: project,
					userId: decodedToken?.id
				});

				// Analytics: 'User Login' event tracking.
				analytics.track('User Login', {
					category: 'Login',
					name: 'User Login',
					sessionId: decodedToken?.sessionId,
				});

				const runwayOrgConfigRequest = new Request(BC_URI.getOrgConfig, {
					method: 'GET',
					headers: new Headers({
						'Authorization': `Bearer ${localStorage.getItem('token')}`,
						'Content-Type': 'application/json',
						'X-PROJECT': project,
						'X-PACKETID': '' + packetId
					}),
				});

				await fetch(runwayOrgConfigRequest).then(async (response) => {
					if (response && response.status >= 200 && response.status < 300) {
						const json = await response.json();
						localStorage.setItem('runwayOrgConfig', JSON.stringify(json.data));
					} else {
						localStorage.setItem('runwayOrgConfig', JSON.stringify({ error: 'Unable to populate Org Config: HTTP Status ' + response.status }));
					}
				}).catch(error => {
					localStorage.setItem('runwayOrgConfig', JSON.stringify({ error: 'Unable to populate Org Config: Error ' + error }));
				});

				packetId = packetId + 1;
				localStorage.setItem('packetId', packetId.toString());

				const termsOfServiceRequest = new Request(BC_URI.termsOfServiceStatus, {
					method: 'GET',
					headers: new Headers({
						'Authorization': `Bearer ${localStorage.getItem('token')}`,
						'Content-Type': 'application/json',
						'X-PROJECT': project,
						'X-PACKETID': '' + packetId
					}),
				});

				await fetch(termsOfServiceRequest).then(async (response) => {
					if (response && response.status >= 200 && response.status < 300) {
						const json = await response.json();
						localStorage.setItem(project + '-termsOfService', JSON.stringify(json.data));
					} else {
						localStorage.setItem(project + '-termsOfService', JSON.stringify({ error: 'Unable to populate Terms Of Service Status: HTTP Status ' + response.status }));
					}
				}).catch(error => {
					localStorage.setItem(project + '-termsOfService', JSON.stringify({ error: 'Unable to populate Terms Of Service Status: Error ' + error }));
				});

				await shareAccessToken();

				// Proceed normally.
				let payload: SessionToken = {};

				payload.token = json.token;
				payload.name = decodedToken.name;
				payload.email = decodedToken.email;
				payload.sessionId = decodedToken.sessionId;
				payload.playerSessionExpiry = decodedToken.playerSessionExpiry;
				payload.iat = decodedToken.iat;
				payload.exp = decodedToken.exp;
				payload.role = decodedToken.role;
				payload.authPayload = decodedToken.authPayload;

				localStorage.setItem(ADD_AUTH_PAYLOAD, JSON.stringify(payload));

				dispatch({
					type: ADD_AUTH_PAYLOAD,
					payload: payload,
				});

				let newUrlArguments: any = {};
				if (json.accessibleTrainingId) {
					newUrlArguments.accessibleTrainingId = json.accessibleTrainingId;
				}
				if (json.accessibleTrainingAppKeyName) {
					newUrlArguments.accessibleTrainingAppKeyName =
						json.accessibleTrainingAppKeyName;
				}
			});
	}, []);

	return login;
};

export default useLogin;
