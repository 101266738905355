import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

const ErrorText = props => {
	const {children} = props;

	return (
		<div
			style={{
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: '#E1E1E1',
				padding: '18px'
			}}
		>
			<div
				style={{
					flex: '1 1 auto',
					color: '#C31C25',
					fontWeight: 'bold',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					fontFamily: 'Helvetica Now Bold'
				}}
			>
				<ErrorIcon style={{marginRight: '4px'}} /> {children}
			</div>
		</div>
	);
};

export default ErrorText;
