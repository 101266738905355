import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {ADD_INPUT_SEARCH_PARAMS, NO_PERMISSIONS_ERROR, HANDOFF_TOKEN} from '../../store/actions/auth';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';

function InitialQuery() {
	let {search, pathname} = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		console.debug("Initial Query", window.location.pathname, window.location.search, window.location.hash);
		
		const params = qs.parse(search);

		// For implicit callbacks resulting from Okta login flow, just return (the local storage should already be populated).
		if(['/implicit/callback', '/vr/implicit/callback', '/implicit/logout', '/vr/implicit/logout'].includes(pathname)) {
			let mergedParams = params;

			const error = params.error;
			if (error) {
				localStorage.setItem(NO_PERMISSIONS_ERROR, "There was a error processing the SAML authentication response");
			} else {
				localStorage.removeItem(NO_PERMISSIONS_ERROR);
			}

			// In case of SAML delete the userId/responseId params.
			delete mergedParams.userId;
			delete mergedParams.responseId;
			delete mergedParams.error;

			try {
				const cachedParams = JSON.parse(
					localStorage.getItem(ADD_INPUT_SEARCH_PARAMS)
				);
				mergedParams = {...cachedParams, ...params};
			} catch (e) {}
	
			dispatch({type: ADD_INPUT_SEARCH_PARAMS, payload: mergedParams});

			if (error) {
				if (localStorage.getItem('isVr') === 'true') {
					history.push("/vr");
				} else {
					history.push("/");
				}
			}

			return;
		}

		// Determine VR mode.
		if (!window.location.pathname.includes('welcome') && !window.location.pathname.includes('pin') && !window.location.pathname.includes('termsOfService')) {
			if (pathname.startsWith('/vr')) {
				localStorage.setItem('isVr', 'true');
			} else {
				localStorage.setItem('isVr', 'false');
			}
		}

		// Determine if we should be redirecting to the Skills Assessmemt Survey.
		if (window.location.pathname.includes('skillsAssessmentSurvey')) {
			localStorage.setItem('isSkillsAssessmentSurvey', 'true');
		}

		const isVr = localStorage.getItem('isVr') === 'true';

		// Determine the previously requested training modules.
		const previouslyRequestedTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];

		// Determine the newly requested training modules.
		const newlyRequestedTrainingModules = [];
		if (params && params.trainingModules) {
			if (Array.isArray(params.trainingModules)) {
				newlyRequestedTrainingModules.push(...params.trainingModules);
			} else {
				newlyRequestedTrainingModules.push(params.trainingModules);
			}
		}

		// If the supplied training modules have changed, clear the current error (if present).
		let trainingModulesUpdated = false;
		newlyRequestedTrainingModules.forEach(item => {
			if (!previouslyRequestedTrainingModules.includes(item)) {
				trainingModulesUpdated = true;
			}
		});
		previouslyRequestedTrainingModules.forEach(item => {
			if (!newlyRequestedTrainingModules.includes(item)) {
				trainingModulesUpdated = true;
			}
		});
		if (trainingModulesUpdated || !window.location.search || !window.location.search.includes('trainingModules') || window.location.pathname.includes('welcome') || window.location.pathname.includes('pin') || (!window.location.pathname.includes('termsOfService') && !window.location.pathname.includes('skillsAssessmentSurvey'))) {
			// console.log("Requested Training Modules have been updated");

			localStorage.removeItem(ADD_INPUT_SEARCH_PARAMS);
			localStorage.removeItem(NO_PERMISSIONS_ERROR);
			localStorage.removeItem(HANDOFF_TOKEN);
			localStorage.removeItem('securityToken');
			localStorage.removeItem('expireSeconds');
			localStorage.removeItem('currentDate');
			localStorage.removeItem('expireDate');
			localStorage.removeItem('token');
			localStorage.setItem('isFirstLoad', 'true');
		}

		// if (window.location.pathname === '/') {
		// 	localStorage.removeItem(NO_PERMISSIONS_ERROR);
		// 	localStorage.removeItem(HANDOFF_TOKEN);
		// 	localStorage.removeItem('securityToken');
		// 	localStorage.removeItem('expireSeconds');
		// 	localStorage.removeItem('currentDate');
		// 	localStorage.removeItem('expireDate');
		// 	localStorage.removeItem('token');
		// 	localStorage.setItem('isFirstLoad', 'true');
		// }

		// console.log("Initial Query", window.location.search);

		// Determine if the newly requested modules are different from the previous.
		// If so we need to clear the no permissions error.
		// if (previouslyRequestedTrainingModules.length != newlyRequestedTrainingModules.length) {
		// 	localStorage.removeItem(NO_PERMISSIONS_ERROR);

		// 	localStorage.removeItem(HANDOFF_TOKEN);
		// 	localStorage.removeItem('securityToken');
		// 	localStorage.removeItem('expireSeconds');
		// 	localStorage.removeItem('currentDate');
		// 	localStorage.removeItem('expireDate');
		// 	localStorage.removeItem('token');
		// 	} else {
		// 	let trainingModulesUpdated = false;
		// 	newlyRequestedTrainingModules.forEach(item => {
		// 		if (!previouslyRequestedTrainingModules.includes(item)) {
		// 			trainingModulesUpdated = true;
		// 		}
		// 	});
		// 	previouslyRequestedTrainingModules.forEach(item => {
		// 		if (!newlyRequestedTrainingModules.includes(item)) {
		// 			trainingModulesUpdated = true;
		// 		}
		// 	});

		// 	if (trainingModulesUpdated) {
		// 		localStorage.removeItem(NO_PERMISSIONS_ERROR);

		// 		localStorage.removeItem(HANDOFF_TOKEN);
		// 		localStorage.removeItem('securityToken');
		// 		localStorage.removeItem('expireSeconds');
		// 		localStorage.removeItem('currentDate');
		// 		localStorage.removeItem('expireDate');
		// 		localStorage.removeItem('token');
		// 	}
		// }

		// Determine the requested training modules.
		//if (newlyRequestedTrainingModules.length > 0) {
			localStorage.setItem("requestedTrainingModules", newlyRequestedTrainingModules.join(','));
			localStorage.removeItem(NO_PERMISSIONS_ERROR);
		// } else {
		// 	localStorage.removeItem("requestedTrainingModules");
		//}

		// Determine the current state of the NO_PERMISSIONS_ERROR.
		const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR);

		// if (isVr) {
		// 	console.log("Initial Query (VR)", pathname, previouslyRequestedTrainingModules, newlyRequestedTrainingModules, noPermissionsError);
		// } else {
		// 	console.log("Initial Query (Desktop)", pathname, previouslyRequestedTrainingModules, newlyRequestedTrainingModules, noPermissionsError);
		// }


		// Merge supplied url paramaters with the current input search parameters.
		let mergedParams = params;
		try {
			const cachedParams = JSON.parse(
				localStorage.getItem(ADD_INPUT_SEARCH_PARAMS)
			);
			mergedParams = {...cachedParams, ...params};
		} catch (e) {}

		dispatch({type: ADD_INPUT_SEARCH_PARAMS, payload: mergedParams});
	}, []);

	return <React.Fragment></React.Fragment>;
}

export default InitialQuery;
