import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ApplicationState from '../../types/store';
import {AUTH_ERROR, CHANGE_PAGE} from '../../store/actions/auth';
import get from 'lodash/get';
import useGeneratePin from '../../hooks/useGeneratePin';

function LoginNavigator() {
	const dispatch = useDispatch();

	const generatePin = useGeneratePin();

	const {page, sessionToken, hasPermissionError, handOffToken} = useSelector(
		(state: ApplicationState) => state.appConfigData
	);

	useEffect(() => {
		if (
			page === 'vr' ||
			page === 'login' ||
			page === '' ||
			page === undefined
		) {
			const token = get(sessionToken, 'token');
			if (!token) {
				return;
			}

			generatePin();
		}
	}, [page, sessionToken]);

	useEffect(() => {
		if (
			page === 'vr' ||
			page === 'login' ||
			page === '' ||
			page === undefined
		) {
			const token = get(sessionToken, 'token');
			if (!token) {
				return;
			}

			const securityToken = get(handOffToken, 'securityToken');

			if (securityToken === 'undefined' || securityToken === null) {
				return;
			}

			const permissionError = hasPermissionError;

			if (
				permissionError !== undefined &&
				permissionError !== 'undefined' &&
				permissionError !== null
			) {
				dispatch({
					type: AUTH_ERROR,
					payload: {
						code: 403,
						message: permissionError,
					},
				});

				return;
			}

			if (securityToken) {
				if (window.location.pathname.includes('/pin')) {
					dispatch({type: CHANGE_PAGE, payload: 'pin'});
				} else {
					dispatch({type: CHANGE_PAGE, payload: 'welcome'});
				}
			}
		}
	}, [page, sessionToken, handOffToken, hasPermissionError]);

	return <React.Fragment></React.Fragment>;
}

export default LoginNavigator;
