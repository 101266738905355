import React, {useEffect} from 'react';

import Button from '@material-ui/core/Button';
import {Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';

import PrimarySearchAppBar from '../appbar';
import {CHANGE_PAGE, NO_PERMISSIONS_ERROR} from '../../store/actions/auth';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import useGeneratePin from '../../hooks/useGeneratePin';

import get from 'lodash/get';
import {HeaderLogo} from '../../components/ui/HeaderLogo';
import {LogoutButton} from '../../components/ui/LogoutButton';

function WelcomePage({isVr, isSkillsAssessmentSurvey}) {
	const generatePin = useGeneratePin();
	const dispatch = useDispatch();

	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	useEffect(() => {
		const isFirstLoad = localStorage.getItem('isFirstLoad') === 'true';
		const requestedTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];

		if (isVr && isFirstLoad) {
			onSubmit();
			localStorage.setItem('isFirstLoad', 'false');
		} else {
			if (isSkillsAssessmentSurvey && isFirstLoad) {
				dispatch({type: CHANGE_PAGE, payload: 'skillsAssessmentSurvey'});
				localStorage.setItem('isSkillsAssessmentSurvey', 'false');
			}
		}
	}, []);

	const onSubmit = () => {
		(!appConfigData.handOffToken ||
			parseInt(appConfigData.handOffToken.expireDate.toString()) - Date.now() <
				0) &&
			generatePin();

		dispatch({type: CHANGE_PAGE, payload: 'pin'});
	};

	const isGeneratingPin = localStorage.getItem('generatingPin') === 'true';
	const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR);
	if (isGeneratingPin || (noPermissionsError != null && noPermissionsError !== 'undefined')) {
		return null;
	}

	return (
		<React.Fragment>
			<PrimarySearchAppBar />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					overflow: 'auto'
				}}
			>
				<div
					style={{minHeight: '160px', display: 'flex', flexDirection: 'column'}}
				>
					<div style={{flex: 6}} />
					<HeaderLogo appConfigData={appConfigData} />
					<div style={{flex: 2}} />
				</div>

				<Typography gutterBottom>
					{get(appConfigData, 'sessionToken.email')}
				</Typography>

				<Title appConfigData={appConfigData} />

				<Body appConfigData={appConfigData} />

				<div style={{height: '24px'}} />

				<GeneratePinButton onClick={onSubmit} />

				<LogoutButton />
			</div>
		</React.Fragment>
	);
}

export default WelcomePage;

function Title(props: {appConfigData: AppConfigData}) {
	return (
		<Typography
			variant='h1'
			gutterBottom
			dangerouslySetInnerHTML={{
				__html: get(
					props.appConfigData,
					'publicAppData.passport.welcome.title'
				),
			}}
		/>
	);
}

function Body(props: {appConfigData: AppConfigData}) {
	return (
		<Typography
			variant='body1'
			gutterBottom
			dangerouslySetInnerHTML={{
				__html: get(props.appConfigData, 'publicAppData.passport.welcome.body'),
			}}
		/>
	);
}

function GeneratePinButton(props: {onClick: () => void}) {
	return (
		<Button
			id={'generate-pin-button'}
			variant='contained'
			style={{fontSize: '18px', fontFamily: 'Helvetica Now Bold', minHeight: 51}}
			onClick={props.onClick}
		>
			Generate PIN
		</Button>
	);
}
