import React from 'react';

interface PROPS {
    size: number,
    x?: number,
    y?: number,
    backgroundColor: string,
    borderColor: string,
    color: string,
    opacity?: number,
    strokePercentage?: number
}

const AchievementAdornment = (props: PROPS) => {
    const { size, x, y, backgroundColor, borderColor, color, opacity, strokePercentage } = props;

    const realWidth = 23.48;
    const realHeight = 23.48;

    const strokeWidth = strokePercentage != null ? strokePercentage * realWidth : 0.07 * realWidth;

    return (
        <svg x={x != null ? x : undefined} y={y != null ? y : undefined} width={size != null ? size : '38px'} height={size != null ? size : '38px'} viewBox={"0 0 " + realWidth + " " + realHeight} xmlns="http://www.w3.org/2000/svg">
            {/* Primary Adornment Shape */}
            <path style={{ fill: backgroundColor, stroke: borderColor, strokeWidth: strokeWidth }} d="M19.8,8.41c-.47-1.13-.32-2.71-1.17-3.56s-2.42-.7-3.55-1.16S13,2,11.74,2,9.49,3.23,8.41,3.69,5.7,4,4.85,4.85s-.7,2.43-1.16,3.56S2,10.49,2,11.74,3.24,14,3.69,15.08s.31,2.7,1.16,3.55,2.43.7,3.56,1.17,2.08,1.68,3.33,1.68S14,20.25,15.08,19.8s2.7-.32,3.55-1.17.7-2.42,1.17-3.55,1.68-2.09,1.68-3.34S20.25,9.49,19.8,8.41Z"/>

            {/* Adorment Checkmark */}
            <path style={{ fill: color, stroke: color }} d="M16.08,8.26a1,1,0,0,0-1.41,0l-3.8,3.8L9.1,10.45a1,1,0,0,0-1.34,1.48l2.47,2.25a.88.88,0,0,0,.19.12s.07.06.12.07a.94.94,0,0,0,.29.06h.07a1,1,0,0,0,.39-.08l.09-.06a.83.83,0,0,0,.23-.15l4.47-4.48A1,1,0,0,0,16.08,8.26Z"/>

            {/* Optional Opacity */}
            {opacity != null &&
                <path style={{ fill: '#FFFFFF', stroke: borderColor, strokeWidth: strokeWidth, fillOpacity: opacity }} d="M19.8,8.41c-.47-1.13-.32-2.71-1.17-3.56s-2.42-.7-3.55-1.16S13,2,11.74,2,9.49,3.23,8.41,3.69,5.7,4,4.85,4.85s-.7,2.43-1.16,3.56S2,10.49,2,11.74,3.24,14,3.69,15.08s.31,2.7,1.16,3.55,2.43.7,3.56,1.17,2.08,1.68,3.33,1.68S14,20.25,15.08,19.8s2.7-.32,3.55-1.17.7-2.42,1.17-3.55,1.68-2.09,1.68-3.34S20.25,9.49,19.8,8.41Z"/>
            }
        </svg>
    );
};

export default AchievementAdornment;
