import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Menu, MenuItem, Theme, Typography, withStyles } from '@material-ui/core';
import SkillsAssessmentResult from '../SkillsAssessmentResult';
import { Scatter } from 'react-chartjs-2';
import "chartjs-plugin-annotation";
import ReactDOMServer from 'react-dom/server';
import HexagonTile from './HexagonTile';
import AchievementAdornment from './AchievementAdornment';
import StackCountAdornment from './StackCountAdornment';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

interface PROPS {
    skillsAssessmentResult: SkillsAssessmentResult,
    selectedSkillCategory: string | null,
    selectedSkill: any,
    onSkillCateogrySelected: (skillCateogry: string) => void,
    onSkillSelected: (skill: any) => void,
    classes: any
}

// Styling for this component.
const styles = (theme: Theme) => createStyles({
    root: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    hexagon: {
        '& svg, & path': {
            fill: 'red',
            stroke: 'blue',
            strokeWidth: 4
        }
    },
    popover: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            overflow: 'visible !important',
            boxShadow: 'unset',
            padding: 5,
            '& .MuiList-root > div': {
                marginBottom: 64,
                outline: 'none'
            }
        }
    },
    popoverSkill: {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        minHeight: 64,
        minWidth: 400,
        maxWidth: 400,
        height: 'auto',
        cursor: 'pointer',
        overflow: 'visible',
        borderRadius: 5,
        position: 'relative',
        '&.MuiMenuItem-root': {
            backgroundColor: 'var(--navigation-background-color)',
            overflow: 'visible',
            '&:not(:first-of-type)': {
                marginTop: 15
            }
        },
        '& .MuiTypography-root': {
            color: 'var(--navigation-font-color)',
            whiteSpace: 'break-spaces',
            textAlign: 'left',
            margin: 0,
            width: '100%',
            maxWidth: 'unset',
            fontSize: 14
        }
    },
    popoverIcon: {
        position: 'absolute',
        top: -15,
        left: -25
    },
    popoverNavigation: {
        minWidth: 32,
        maxWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 16,
        overflow: 'hidden',
        backgroundColor: 'var(--navigation-background-color)',
        '& .MuiSvgIcon-root': {
            width: 32,
            height: 32,
            fontSize: 32,
            cursor: 'pointer',
            color: 'var(--navigation-font-color)'
        }
    }
});

const encodeSvg = (reactElement) => {
    return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
}

const ScoreChart = (props: PROPS) => {
    const { classes, skillsAssessmentResult, selectedSkillCategory, selectedSkill } = props;

    const skillCategories = skillsAssessmentResult.skills;
    const skillCategoryKeys = Object.keys(skillCategories).sort((a: string, b: string) => {
        const aCategory = skillsAssessmentResult.skills[a];
        const bCategory = skillsAssessmentResult.skills[b];

        if (aCategory.sortOrder === bCategory.sortOrder) return 0;
        else if (aCategory.sortOrder < bCategory.sortOrder) return -1;
        else return 1;
    });

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const [popoverSkills, setPopoverSkills] = useState([]);
    const [popoverPage, setPopoverPage] = useState(0);

    const selectedCategory = selectedSkillCategory ? skillsAssessmentResult.skills[selectedSkillCategory] : null;

    const brandingData = useSelector(state => (state as any).brandingData);

    const getCurrentPopoverPage = () => {
        const startIdx = popoverPage * 3;
        const endIdx = startIdx + 3;

        const currentPage = [];

        for (let x = startIdx; x < endIdx; x++) {
            if (popoverSkills[x]) currentPage.push(popoverSkills[x]);
        }

        return currentPage;
    }

    const popoverPageCount = Math.ceil(popoverSkills.length / 3);
    const currentPopoverPage = getCurrentPopoverPage();

    const handleSkillClicked = (skill: any) => {
        if (props.onSkillSelected != null && selectedSkill !== skill) {
            let category = null;

            skillCategoryKeys.forEach(key => {
                if (skillCategories[key] && skillCategories[key].items.includes(skill)) {
                    category = key;
                }
            });

            props.onSkillCateogrySelected(category);
            props.onSkillSelected(skill);

            setPopoverOpen(false);
            setPopoverPosition({ top: 0, left: 0 });
            setPopoverSkills([]);
            setPopoverPage(0);
        }
    };

    const handleChartElementClicked = (event: any, element: any) => {
        if (element && element.length > 0) {
            const chart = element[0];

            const datasetIndex = chart._datasetIndex;
            const index = chart._index;
            const value = chart._chart.config.data.datasets[datasetIndex].data[index];

            const skills = selectedCategory.items.filter(item => {
                const roleRating = item.expectedRoleValue != null ? item.expectedRoleValue : 1;
                const userRating = item.userValue != null ? item.userValue : 1;

                return userRating === value.x && roleRating === value.y;
            });

            setPopoverPage(0);
            setPopoverSkills(skills);
            setPopoverPosition({ top: event.y, left: event.x });
            setPopoverOpen(true);
        } else {
            setPopoverOpen(false);
            setPopoverPosition({ top: 0, left: 0 });
            setPopoverSkills([]);
            setPopoverPage(0);
        }
    };

    const handlePopoverNextPage = () => {
        if (popoverPage + 1 < popoverPageCount) {
            setPopoverPage(popoverPage + 1);
        }
    }

    const handlePopoverPrevPage = () => {
        if (popoverPage - 1 >= 0) {
            setPopoverPage(popoverPage - 1);
        }
    }

    if (!selectedCategory || !selectedCategory.items) {
        return null;
    }

    const dataPoints = [];

    selectedCategory.items.forEach(item => {
        const roleRating = item.expectedRoleValue != null ? item.expectedRoleValue : 1;
        const userRating = item.userValue != null ? item.userValue : 1;

        const existingDataPoint = dataPoints.find(dataPoint => dataPoint.x === userRating && dataPoint.y === roleRating);

        if (existingDataPoint) {
            existingDataPoint.stackCount++;
            if (item === selectedSkill && !existingDataPoint.selected) existingDataPoint.selected = true;
        } else {
            dataPoints.push({
                x: userRating,
                y: roleRating,
                stackCount: 1,
                selected: item === selectedSkill
            });
        }
    });


    const data = {
        labels: dataPoints.map(dataPoint => '' + dataPoint.stackCount),
        datasets: [{
            type: 'scatter',
            label: 'Skill Assessment',
            showLine: false,
            data: dataPoints,
            pointRadius: 18,
            pointHoverRadius: 18,
            pointStyle: (args: any) => {
                const { dataIndex, dataset } = args;

                if (dataIndex != null && dataset != null && dataset.data[dataIndex]) {
                    const dataValue = dataset.data[dataIndex];

                    const hexagonImage = new Image();
                    hexagonImage.src = encodeSvg(
                        <HexagonTile
                            strokePercentage={dataValue.selected ? 0.06 : 0.04}
                            size={dataValue.selected ? 64 : 48}
                            backgroundColor={selectedCategory.mainColour}
                            borderColor={dataValue.selected ? '#808080' : selectedCategory.borderColour}
                            adornment={(size: number, x: number, y: number) => {
                                if (dataValue.stackCount > 1) {
                                    return <StackCountAdornment
                                        size={size + 16}
                                        x={x}
                                        y={y}
                                        backgroundColor={'#FFFFFF'}
                                        borderColor={dataValue.selected ?'#808080' : selectedCategory.borderColour}
                                        color={dataValue.selected ? 'black' : selectedCategory.borderColour}
                                        strokePercentage={0.10}
                                        label={dataValue.stackCount}
                                    />;
                                } else {
                                    return null;
                                }
                            }}
                        />
                    );

                    return hexagonImage;
                } else {
                    const hexagonImage = new Image();
                    hexagonImage.src = encodeSvg(
                        <HexagonTile
                            strokePercentage={0.04}
                            size={48}
                            backgroundColor={selectedCategory.mainColour}
                            borderColor={selectedCategory.borderColour}
                        />
                    );

                    return hexagonImage;
                }
            },
            backgroundColor: selectedCategory.mainColour,
            borderColor: selectedCategory.borderColour,
            zIndex: 999
        // },{
        //     type: 'line',
        //     label: 'Divider Line',
        //     data: [
        //         {x: 7, y: 7},
        //         {x: 6, y: 6},
        //         {x: 5, y: 5},
        //         {x: 4, y: 4},
        //         {x: 3, y: 3},
        //         {x: 2, y: 2},
        //         {x: 1, y: 1},
        //     ],
        //     fill: 'end',
        //     backgroundColor: 'rgba(160, 196, 41, 0.1)',
        //     borderColor: 'rgb(160, 196, 41)',
        //     borderWidth: 1,
        //     zIndex: 1,
        //     pointRadius: 0,
        //     pointHoverRadius: 0,
        }
    ]
    };

    const options = {
        onClick: handleChartElementClicked,
        layout: {
            padding: {
                left: 50,
                right: 50,
                top: 50,
                bottom: 50
            }
        },
        legend: {
            display: false,
        },
        animation: {
            duration: 1
        },
        scales: {
            xAxes: [{
                id: 'x-axis-0',
                type: 'linear',
                position: 'bottom',
                scaleLabel: {
                    display: true,
                    labelString: 'USER SCORE',
                    fontColor: brandingData['page-primary-font-color'],
                    fontSize: 20,
                    padding: 30
                },
                ticks: {
                    // reverse: true,
                    min: 1,
                    max: 7,
                    fontColor: brandingData['page-primary-font-color'],
                    padding: 20,
                    fontSize: 18,
                    fontFamily: 'Arial'
                },
                gridLines: {
                    color: [brandingData['page-primary-font-color'], 'transparent', 'transparent', brandingData['page-primary-font-color'], 'transparent', 'transparent', brandingData['page-primary-font-color']]
                }
            }],
            yAxes: [{
                id: 'y-axis-0',
                type: 'linear',
                position: 'left',
                scaleLabel: {
                    display: true,
                    labelString: 'TARGET SCORE',
                    fontColor: brandingData['page-primary-font-color'],
                    fontSize: 20
                },
                ticks: {
                    min: 1,
                    max: 7,
                    fontColor: brandingData['page-primary-font-color'],
                    padding: 30,
                    fontSize: 18,
                    fontFamily: 'Arial'
                },
                gridLines: {
                    color: [brandingData['page-primary-font-color'], 'transparent', 'transparent', brandingData['page-primary-font-color'], 'transparent', 'transparent', brandingData['page-primary-font-color']]
                }
            }]
        },
        tooltips: {
            enabled: false,
            callbacks: {
                label: function (item, data) {
                    const stackCount = data.datasets[item.datasetIndex].data[item.index].stackCount
                    return stackCount + ' Skill' + (stackCount > 1 ? 's' : '');
                }
            }
        },
        annotation: {
            annotations: [
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    xScaleID: 'x-axis-0',
                    yMin: 1,
                    yMax: 4,
                    xMin: 1,
                    xMax: 4,
                    backgroundColor: '#F8F8EF'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    xScaleID: 'x-axis-0',
                    yMin: 1,
                    yMax: 4,
                    xMin: 4,
                    xMax: 7,
                    backgroundColor: '#F3F8F1'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    xScaleID: 'x-axis-0',
                    yMin: 4,
                    yMax: 7,
                    xMin: 1,
                    xMax: 4,
                    backgroundColor: '#F7F1F2'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    xScaleID: 'x-axis-0',
                    yMin: 4,
                    yMax: 7,
                    xMin: 4,
                    xMax: 7,
                    backgroundColor: '#F8F8EF'
                }
            ]
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ flex: '1 1 auto', position: 'relative' }}>
                <Scatter
                    width={600}
                    height={600}
                    data={data}
                    options={options}
                />

                <label style={{ fontSize: '12px', position: 'absolute', top: 10, left: 190 }}>Valley of Opportunity</label>
                <label style={{ fontSize: '12px', position: 'absolute', top: 10, right: 90 }}>Summit of Proficiency</label>
                <label style={{ fontSize: '12px', position: 'absolute', bottom: 120, left: 170 }}>"Good Enough" Junction</label>
                <label style={{ fontSize: '12px', position: 'absolute', bottom: 120, right: 70 }}>Cliffs of Overperformance</label>

                <Menu
                    id={'stacked-skill-popover'}
                    className={classes.popover}
                    open={popoverOpen}
                    anchorPosition={popoverPosition}
                    anchorReference={'anchorPosition'}
                    onClose={() => setPopoverOpen(false)}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {popoverPage > 0 &&
                            <div className={classes.popoverNavigation} style={{ marginRight: 32 }}>
                                <ArrowLeftIcon onClick={handlePopoverPrevPage} />
                            </div>
                        }
                        {popoverPage === 0 &&
                            <div style={{ minWidth: 32, maxWidth: 32, minHeight: 32, maxHeight: 32, marginRight: 32 }}>
                            </div>
                        }

                        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5 }}>
                            {currentPopoverPage.map((skill, idx) => {
                                return (
                                    <MenuItem key={idx} onClick={() => handleSkillClicked(skill)} className={classes.popoverSkill}>
                                        <div className={classes.popoverIcon} style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 72, height: 72 }}>
                                            <HexagonTile
                                                strokePercentage={skill.criticalSkill ? 0.06 : 0}
                                                size={skill === selectedSkill ? 72 : 64}
                                                backgroundColor={selectedCategory.mainColour}
                                                borderColor={skill === selectedSkill ? '#808080' : selectedCategory.borderColour}
                                                label={skill.displayShortName}
                                                adornment={(size: number, x: number, y: number) => {
                                                    if (skill.userValue >= skill.expectedRoleValue) {
                                                        return <AchievementAdornment
                                                            size={size}
                                                            x={x}
                                                            y={y}
                                                            backgroundColor={selectedCategory.mainColour}
                                                            borderColor={skill === selectedSkill ? '#808080' : selectedCategory.borderColour}
                                                            color={'#FFFFFF'}
                                                            strokePercentage={(skill.criticalSkill ? 0.06 : 0) * 2}
                                                        />;
                                                    } else {
                                                        return null;
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div style={{ paddingLeft: 30, paddingRight: 5, flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden' }}>
                                            <Typography style={{ flex: '1 1 auto', fontWeight: 'bold' }}>{skill.displayTitle}</Typography>
                                            <Typography style={{ marginTop: 5 }}>{skill.displayDescription}</Typography>
                                        </div>

                                        <div style={{ paddingLeft: 10, flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', overflow: 'hidden', borderLeft: '2px solid white' }}>
                                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: 'auto' }}>Your Score: </Typography>
                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: '10px', color: skill.userValue >= skill.expectedRoleValue ? 'rgb(0, 255, 0)' : 'inherit' }}>{skill.userValue}</Typography>
                                            </div>
                                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: 'auto' }}>Target Score: </Typography>
                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: '10px' }}>{skill.expectedRoleValue}</Typography>
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </div>

                        {popoverPage < popoverPageCount - 1 &&
                            <div className={classes.popoverNavigation} style={{ marginLeft: 32 }}>
                                <ArrowRightIcon onClick={handlePopoverNextPage} />
                            </div>
                        }
                        {popoverPage === popoverPageCount - 1 &&
                            <div style={{ minWidth: 32, maxWidth: 32, minHeight: 32, maxHeight: 32, marginLeft: 32 }}>
                            </div>
                        }
                    </div>
                </Menu>
            </div>
        </div>
    );
};

export default withStyles(styles)(ScoreChart);
