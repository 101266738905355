import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { project, BC_URI } from 'utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import AppConfigData from 'types/brainCloud/AppConfigData';
import { HeaderLogo } from 'components/ui/HeaderLogo';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LogoutButton } from 'components/ui/LogoutButton';
import { jsonToSearchParams } from 'utils';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { NO_PERMISSIONS_ERROR } from 'store/actions/auth';
import SystemMessage from 'sections/SystemMessage';

const TermsOfService = props => {
    const systemMessage = useSelector((state: {appConfigData: AppConfigData}) => {
        return state.appConfigData?.publicAppData?.systemMessage;
    });

    const isVr = props.isVr;

    const history = useHistory();

    const appConfigData = useSelector((state: { appConfigData: AppConfigData }) => {
        return state.appConfigData;
    });

    const urlInputArguments = useSelector(
        (state: { appConfigData: AppConfigData }) => {
            return state.appConfigData.urlInputArguments;
        }
    );

    const mainLogo = get(
        appConfigData,
        'publicAppData.images.passportPostLoginLogo'
    );

    const runwayOrgConfigRaw = localStorage.getItem('runwayOrgConfig');
    const runwayOrgConfig = runwayOrgConfigRaw ? JSON.parse(runwayOrgConfigRaw) : null;
    const termsOfServiceRaw = localStorage.getItem(project + '-termsOfService');
    const termsOfService = termsOfServiceRaw ? JSON.parse(termsOfServiceRaw) : null;

    const [currentTab, setCurrentTab] = useState(null);
    const [accepted, setAccepted] = useState(termsOfService && termsOfService.userAccepted ? true : false);

    const handleTermsClick = () => {
        setCurrentTab('termsPassport');
    };

    const handlePrivacyClick = () => {
        setCurrentTab('privacyPassport');
    };

    const handleBackClick = () => {
        setCurrentTab(null);
    };

    const handleAcceptAll = async () => {
        let packetId = parseInt(localStorage.getItem('packetId')) + 1;
        if (!packetId) packetId = 1;
        localStorage.setItem('packetId', packetId.toString());

        const request = new Request(BC_URI.termsOfServiceStatus, {
            method: 'POST',
            headers: new Headers({
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
                'X-PROJECT': project,
                'X-PACKETID': '' + packetId
            }),
            body: JSON.stringify(termsOfService.termsEntity)
        });

        await fetch(request).then(async (response) => {
            if (response && response.status >= 200 && response.status < 300) {
                termsOfService.userAccepted = true;
                delete termsOfService.error;
                localStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));

                const redirectTarget = '/welcome?' + jsonToSearchParams(urlInputArguments);

                // console.log("Redirecting", redirectTarget);

                history.push(redirectTarget);
            } else {
                termsOfService.error = 'Unable to accept Terms Of Service: HTTP Status ' + response.status;
                localStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));
            }
        }).catch(error => {
            termsOfService.error = 'Unable to accept Terms Of Service: Error ' + error;
            localStorage.setItem(project + '-termsOfService', JSON.stringify(termsOfService));
        });
    };

    // Safety-Net: If the user has already accepted the Terms Of Service, redirect them to welcome.
    // if (!runwayOrgConfig || !termsOfService || (termsOfService.userAccepted && termsOfService.error == null)) {
    //     const redirectTarget = isVr ? '/vr/welcome?' + jsonToSearchParams(urlInputArguments) : '/welcome?' + jsonToSearchParams(urlInputArguments);

    //     console.log("Safety Net Redirecting", redirectTarget);

    //     history.push(redirectTarget);
    //     return null;
    // }

    const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR);

    if (noPermissionsError && noPermissionsError !== 'undefined') return null;

    if ( (localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : []).length > 0) {
        if (!localStorage.getItem('securityToken')) {
            return null;
        }
    }

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            {systemMessage && systemMessage.enabled && !systemMessage.afterLogin &&
                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}>
                    <SystemMessage includeTopMargin={false} />
                </div>
            }
            
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                {!isVr &&
                    <div id="tos-mainlogo">
                        <HeaderLogo appConfigData={appConfigData} />
                    </div>
                }
                {isVr && mainLogo &&
                    <img id="tos-mainlogo-vr"
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '5px',
                            marginBottom: '5px',
                            width: '234px',
                            display: 'block',
                        }}
                        src={mainLogo}
                    />
                }

                {currentTab != null && !isVr &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', left: 20, top: 40 }} onClick={handleBackClick}>
                        <ArrowBackIosIcon fontSize="large" style={{ color: 'var(--navigation-icon-color)' }} />
                    </div>
                }
                
                {currentTab != null && isVr &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', left: 20, top: 6 }} onClick={handleBackClick}>
                        <ArrowBackIosIcon fontSize="large" style={{ color: 'var(--navigation-icon-color)' }} />
                    </div>
                }
                {currentTab != null && isVr &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', right: 20, top: 6 }} onClick={handleBackClick}>
                        <ArrowForwardIosIcon fontSize="large" style={{ display: 'none', color: 'var(--navigation-icon-color)' }} />
                    </div>
                }
            </div>

            {currentTab == null && !isVr &&
                <DialogTitle id="tos-dialog-title" style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }} disableTypography={true}>
                    <Typography variant={'h6'} style={{ fontWeight: 'bold' }}>{'Legal Information Update'}</Typography>
                    {termsOfService && termsOfService.termsEntity &&
                        <label style={{ marginLeft: '20px' }}>{'Version'}: {moment(termsOfService.termsEntity.modifiedAt).format('MM/DD/YYYY')}</label>
                    }
                </DialogTitle>
            }
            {currentTab == null && isVr &&
                <DialogTitle id="tos-dialog-title-vr" style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px !important' }} disableTypography={true}>
                    <Typography variant={'h6'} style={{ fontWeight: 'bold' }}>{'Legal Information Update'}</Typography>
                    {termsOfService && termsOfService.termsEntity &&
                        <label style={{ marginLeft: '20px' }}>{'Version'}: {moment(termsOfService.termsEntity.modifiedAt).format('MM/DD/YYYY')}</label>
                    }
                </DialogTitle>
            }

            <DialogContent style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', overflow: currentTab == null ? 'auto' : 'hidden', marginBottom: '56px', paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                {currentTab == null &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ maxWidth: '440px', margin: '0px', padding: '0px', fontStyle: 'italic', textAlign: 'center', width: 'auto' }}>
                            {`
                        Our legal information is updated from time to time.
                        Please review the below information and accept the latest version of our Terms of Service and Privacy Policy to continue.
                    `}
                        </Typography>
                    </div>
                }

                {currentTab == null &&
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Button id="tos-terms-of-service-button" variant="contained" onClick={handleTermsClick}>{'Terms of Service'}</Button>
                        <Button id="tos-privacy-policy-button" style={{ marginTop: '20px' }} variant="contained" onClick={handlePrivacyClick}>{'Privacy Policy'}</Button>
                    </div>
                }

                {currentTab === 'termsPassport' &&
                    <Typography variant="subtitle1" style={{ flex: '0 0 auto', fontWeight: 'bold', textAlign: 'center', textDecoration: 'none', width: 'auto', marginTop: '8px' }}>Terms Of Service</Typography>
                }
                {currentTab === 'privacyPassport' &&
                    <Typography variant="subtitle1" style={{ flex: '0 0 auto', fontWeight: 'bold', textAlign: 'center', textDecoration: 'none', width: 'auto', marginTop: '8px' }}>Privacy Policy</Typography>
                }
                {currentTab != null &&
                    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'scroll', paddingLeft: '24px', paddingRight: '24px' }}>
                        {currentTab === 'termsPassport' &&
                            <div dangerouslySetInnerHTML={{ __html: termsOfService && termsOfService.termsEntity ? termsOfService.termsEntity.termsPassport : ''}}></div>
                        }

                        {currentTab === 'privacyPassport' &&
                            <div dangerouslySetInnerHTML={{ __html: termsOfService && termsOfService.termsEntity ? termsOfService.termsEntity.privacyPassport : ''}}></div>
                        }
                    </div>
                }

                {currentTab == null &&
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                        <FormControlLabel
                            style={{ margin: '0px', padding: '0px', maxWidth: '300px', color: 'var(--page-secondary-font-color)' }}
                            control={
                                <Checkbox
                                    checked={accepted}
                                    onChange={() => setAccepted(!accepted)}
                                    size="medium"
                                    style={{ color: 'var(--page-secondary-font-color)' }}
                                />
                            }
                            label={<Typography style={{ margin: '0px', padding: '0px', textAlign: 'left', color: 'var(--page-secondary-font-color)' }}>{'I have read and agree to the Terms of Service and Privacy Policy'}</Typography>}
                        />
                    </div>
                }

                {currentTab == null && termsOfService && termsOfService.error &&
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', borderStyle: 'solid', borderWidth: '1px', padding: '5px', marginBottom: '10px' }}>
                        <label>{termsOfService.error}</label>
                    </div>
                }

                {currentTab == null && !isVr &&
                    <DialogActions id="tos-dialog-actions" style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingBottom: '56px' }} disableSpacing={true}>
                        <Button id="tos-continue-button" variant="contained" disabled={!accepted} onClick={handleAcceptAll}>{'Continue'}</Button>
                        <LogoutButton />
                    </DialogActions>
                }
                {currentTab == null && isVr &&
                    <DialogActions id="tos-dialog-actions-vr" style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingBottom: '56px' }} disableSpacing={true}>
                        <Button id="tos-continue-button" variant="contained" disabled={!accepted} onClick={handleAcceptAll}>{'Continue'}</Button>
                        <LogoutButton />
                    </DialogActions>
                }
            </DialogContent>
        </div>
    );
};

export default TermsOfService;
