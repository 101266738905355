import React from 'react';

interface PROPS {
    width: number,
    height: number,
    backgroundColor: string,
    color: string
}

const SwitchThumbIcon = (props: PROPS) => {
    const { width, height, backgroundColor, color } = props;

    return (
        <svg width={width} height={height} viewBox={"0 0 100 100"} style={{ backgroundColor: backgroundColor, borderRadius: height / 2, overflow: 'hidden' }} xmlns="http://www.w3.org/2000/svg">
            <text textAnchor="middle" alignmentBaseline="middle" x={50} y={55} style={{ fontFamily: 'Arial', fontWeight: 'bold', fill: color ? color : '#FFFFFF', stroke: color ? color : '#FFFFFF', fontSize: height * 0.8 }}>Yes</text>
        </svg>
    );
};

export default SwitchThumbIcon;
