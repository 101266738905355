import React from 'react';
import { Typography } from '@material-ui/core';
import SkillsAssessmentResult from '../SkillsAssessmentResult';
import ScoreChart from './ScoreChart';
import HexagonTile from './HexagonTile';

interface PROPS {
    skillsAssessmentResult: SkillsAssessmentResult,
    selectedSkillCategory: string | null,
    selectedSkill: any,
    showNonCriticalSkills: boolean,
    onSkillCateogrySelected: (skillCateogry: string) => void,
    onSkillSelected: (skill: any) => void,
}
const YourSkillsGraph = (props: PROPS) => {
    const { skillsAssessmentResult, selectedSkillCategory, selectedSkill } = props;

    const skillCategories = skillsAssessmentResult.skills;
    const skillCategoryKeys = Object.keys(skillCategories).sort((a: string, b: string) => {
        const aCategory = skillsAssessmentResult.skills[a];
        const bCategory = skillsAssessmentResult.skills[b];

        if (aCategory.sortOrder === bCategory.sortOrder) return 0;
        else if (aCategory.sortOrder < bCategory.sortOrder) return -1;
        else return 1;
    });

    const handleSkillCategoryClicked = (value: string) => {
        if (props.onSkillCateogrySelected != null && selectedSkillCategory !== value) {
            props.onSkillCateogrySelected(value);
        }
    };

    const handleSkillClicked = (skill: any) => {
        if (props.onSkillSelected != null && selectedSkill !== skill) {
            let category = null;

            skillCategoryKeys.forEach(key => {
                if (skillCategories[key] && skillCategories[key].items.includes(skill)) {
                    category = key;
                }
            });
        
            props.onSkillCateogrySelected(category);
            props.onSkillSelected(skill);
        }
    };

    return (
        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginTop: 25 }}>
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'stretch', marginBottom: 10 }}>
                <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: '400px', maxWidth: '400px' }}>
                    <Typography variant={'h6'} style={{ flex: '1 1 auto', fontWeight: 'bold', fontSize: '24px', marginBottom: 10 }}>Your Skills Graph</Typography>
                    <Typography style={{ flex: '1 1 auto', margin: 0, textAlign: 'left' }}>Select a skill to view on the graph.</Typography>
                </div>

                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '600px', maxWidth: '600px' }}>
                    {skillsAssessmentResult.jobRole &&
                        <Typography style={{ fontWeight: 'bold', marginTop: 0, marginBottom: 10, marginLeft: 90, marginRight: 0 }}>Role: {skillsAssessmentResult.jobRole}</Typography>
                    }
                    <Typography style={{ fontSize: '14px', marginTop: 0, marginBottom: 0, marginLeft: 90, marginRight: 0 }}>You can select any skill in the graph to see what it is.</Typography>
                </div>
            </div>

            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <div style={{ flex: '1 1 0px', display: 'flex', flexDirection: 'column', minWidth: '400px', maxWidth: '400px' }}>
                    {skillCategoryKeys.map((key, idx) => {
                        const skillCategory = skillCategories[key] as any;

                        return (
                            <div key={idx} style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', marginTop: 10, cursor: 'pointer' }} onClick={() => handleSkillCategoryClicked(key)}>
                                <div style={{ width: 64, height: 64, flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <HexagonTile
                                        strokePercentage={0.06}
                                        size={selectedSkillCategory === key ? 64 : 48}
                                        backgroundColor={skillCategory.mainColour}
                                        borderColor={selectedSkillCategory === key ? '#808080' : skillCategory.borderColour}
                                        color={'#FFFFFF'}
                                    />

                                    {skillCategory.iconUrl != null &&
                                        <img style={{ position: 'absolute', zIndex: 1, top: selectedSkillCategory === key ? 16 : 20, left: selectedSkillCategory === key ? 16 : 20, width: selectedSkillCategory === key ? 32 : 24, height: selectedSkillCategory === key ? 32 : 24 }} src={skillCategory.iconUrl} />
                                    }
                                </div>

                                <Typography style={{ marginLeft: 20, fontWeight: selectedSkillCategory === key ? 'bold' : 'normal', textAlign: 'left', width: 'auto' }}>{key} Skills</Typography>
                            </div>
                        );
                    })}
                </div>

                <div style={{ flex: '1 1 0px', display: 'flex', minWidth: '600px', maxWidth: '600px' }}>
                    <ScoreChart skillsAssessmentResult={skillsAssessmentResult} selectedSkillCategory={selectedSkillCategory} onSkillCateogrySelected={handleSkillCategoryClicked} selectedSkill={selectedSkill} onSkillSelected={handleSkillClicked} />
                </div>
            </div>
        </div>
    );
};

export default YourSkillsGraph;
