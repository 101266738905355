import { Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';
import { QuestionEvent } from './SkillsAssessmentState';

interface PROPS {
    questionEvent: QuestionEvent,
    selected?: number | null,
    onAnswerSelected: (eventId: string, answer: any) => void
}
const RatingAnswers = (props: PROPS) => {
    const { questionEvent, selected } = props;
    const answers = questionEvent.answers;

    const handleAnswerSelected = (eventId: string, answer: number) => {
        props.onAnswerSelected(eventId, answer);
    }

    return (
        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <div style={{ flex: '0 0 auto', minWidth: 300, maxWidth: 300 }}>
                <Typography variant={'h6'} style={{ fontWeight: 'bold' }}>{questionEvent.question}</Typography>
            </div>

            <RadioGroup value={'' + selected} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'stretch', flexWrap: 'nowrap' }}>
                {answers.map((answer, idx) => {
                    return (
                        <div key={idx} id={'survey-answer'} style={{ minWidth: '110px', maxWidth: '110px', flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-start', cursor: 'pointer' }} onClick={() => handleAnswerSelected(questionEvent.eventId, idx)}>
                            <div style={{ flex: '0 0 auto', display: 'flex', justifyContent: 'center' }}>
                                <Radio checked={selected === answers.indexOf(answers[idx])} value={idx} />
                            </div>

                            <div style={{ flex: '0 0 auto' }}>
                                <Typography style={{ margin: 0, textAlign: 'center', width: '100%', maxWidth: 'unset', fontWeight: 'bold', fontSize: '0.75rem' }}>{answer.answer}</Typography>
                            </div>
                        </div>
                    );
                })}
            </RadioGroup>
        </div>
    );
};

export default RatingAnswers;
