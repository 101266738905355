import {Online} from 'react-detect-offline';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Email from './form/Email';
import Okta from './form/Okta';
import Saml from './form/Saml';
import Universal from './form/Universal';
import {useSelector} from 'react-redux';
import Header from './Header';
import AuthError from './AuthError';
import PublicAppData from '../../types/brainCloud/PublicAppData';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import AuthType from 'types/enums/AuthType';
import { NO_PERMISSIONS_ERROR } from 'store/actions/auth';
import SystemMessage from 'sections/SystemMessage';

const LoginPage = props => {
    const systemMessage = useSelector((state: {appConfigData: AppConfigData}) => {
        return state.appConfigData?.publicAppData?.systemMessage;
    });

	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) => {
			return state.appConfigData.publicAppData;
		}
	);

	const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined' ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null;

	const isGeneratingPin = localStorage.getItem('generatingPin') === 'true';

	if (isGeneratingPin && !noPermissionsError) {
		return null;
	}

	// localStorage.removeItem('id_token');

	if (publicAppData && publicAppData.authType)
		return (
			<div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
				{systemMessage && systemMessage.enabled && !systemMessage.afterLogin &&
					<SystemMessage includeTopMargin={false} />
				}
				
				<div style={{flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
					<Header />
					
					{publicAppData.authType === AuthType.Universal &&
						<Universal />
					}
					{publicAppData.authType === AuthType.Email &&
						<Email />
					}
					{publicAppData.authType === AuthType.External && publicAppData.externalIdentityName === 'ssoSignin' &&
						<Okta />
					}
					{publicAppData.authType === AuthType.External && publicAppData.externalIdentityName === 'ssoSaml' &&
						<Saml />
					}

					<AuthError />
				</div>
			</div>
		);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%'
			}}
		>
			<div style={{flex: 4}} />
			<div style={{width: '100%'}}>
				<div style={{margin: 'auto', textAlign: 'center'}}>
					<Online>
						<CircularProgress size={40} thickness={4} />
					</Online>
				</div>
			</div>
			<div style={{flex: 8}} />
		</div>
	);
};

export default LoginPage;
