import SessionToken from 'types/brainCloud/SessionToken';
import AppConfigData from 'types/brainCloud/AppConfigData';
import { BrandingCSSData } from 'types/brainCloud/BrandingData';

export default interface ApplicationState {
	sessionToken?: SessionToken;
	appConfigData?: AppConfigData;
	brandingData?: BrandingCSSData;
	stateChange: number;
}

export const mapStateToProps = (state: any) => {
	return {
		sessionToken: state.sessionToken,
		appConfigData: state.appConfigData,
		brandingData: state.brandingData,
		stateChange: state.stateChange
	};
};
