import React from 'react';

const FieldLayout = props => {
	const {children} = props;

	return (
		<div style={{height: '110px', display: 'flex', flexDirection: 'column'}}>
			{children}
		</div>
	);
};

export default FieldLayout;
