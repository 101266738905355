import React from 'react';
import OuterBox from '../../components/layout/OuterBox';
import InnerBox from '../../components/layout/InnerBox';
import {useSelector} from 'react-redux';
import PublicAppData from '../../types/brainCloud/PublicAppData';
import AppConfigData from '../../types/brainCloud/AppConfigData';

const Header = (props) => {
	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) => {
			return state.appConfigData.publicAppData;
		}
	);

	return (
		<OuterBox>
			<InnerBox>
				{publicAppData?.images?.passportLoginLogo ? (
					<img
						style={{
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: '40px',
							marginBottom: '40px',
							width: '234px',
							display: 'block',
						}}
						src={publicAppData?.images?.passportLoginLogo}
					/>
				) : null}
			</InnerBox>
		</OuterBox>
	);
};

export default Header;
