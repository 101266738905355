import React, { useState } from 'react';
import { createStyles, MenuItem, Popover, Switch, Theme, Typography, withStyles } from '@material-ui/core';
import SkillsAssessmentResult from '../SkillsAssessmentResult';
import HexagonTile from './HexagonTile';
import AchievementAdornment from './AchievementAdornment';
import SwitchThumbIcon from './SwitchThumbIcon';

const CustomSwitch = withStyles({
    root: {
        width: 122,
        height: 42,
        padding: 0,
        margin: 0,
        '& .MuiSwitch-colorSecondary + .MuiSwitch-track': {
            backgroundColor: 'lightgrey',
            opacity: 0.5
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'lightgrey',
            opacity: 1
        },
        '& .MuiSwitch-colorSecondary': {
            color: 'var(--tos-terms-of-service-button-background-color)',
            transform: 'translateX(60px)'
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: 'var(--tos-terms-of-service-button-hover-background-color)',
            transform: 'translateX(1px)'
        },
    },
    switchBase: {
        padding: 1
    },
    thumb: {
        width: 60,
        height: 40,
        borderRadius: 21
    },
    track: {
        borderRadius: 21,
        opacity: 1
    }
})(Switch);

interface PROPS {
    skillsAssessmentResult: SkillsAssessmentResult,
    selectedSkillCategory: string | null,
    selectedSkill: any,
    showNonCriticalSkills: boolean,
    onSkillCateogrySelected: (skillCateogry: string) => void,
    onSkillSelected: (skill: any) => void,
    onShowNonCriticalSkillsChanged: (selected: boolean) => void,
    classes: any
}

// Styling for this component.
const styles = (theme: Theme) => createStyles({
    root: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column'
    },
    popover: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            overflow: 'visible !important',
            boxShadow: 'unset',
            padding: 5,
            '& .MuiList-root > div': {
                marginBottom: 64,
                outline: 'none'
            }
        }
    },
    popoverSkill: {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        minHeight: 64,
        minWidth: 400,
        maxWidth: 400,
        height: 'auto',
        cursor: 'pointer',
        overflow: 'visible',
        borderRadius: 5,
        position: 'relative',
        '&.MuiMenuItem-root': {
            backgroundColor: 'var(--navigation-background-color)',
            overflow: 'visible',
            '&:not(:first-of-type)': {
                marginTop: 15
            }
        },
        '& .MuiTypography-root': {
            color: 'var(--navigation-font-color)',
            whiteSpace: 'break-spaces',
            textAlign: 'left',
            margin: 0,
            width: '100%',
            maxWidth: 'unset',
            fontSize: 14
        }
    },
    popoverIcon: {
        position: 'absolute',
        top: -15,
        left: -25
    }
});

const YourSkillsMap = (props: PROPS) => {
    const { classes, skillsAssessmentResult, selectedSkillCategory, selectedSkill, showNonCriticalSkills } = props;

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [popoverSkillCategory, setPopoverSkillCategory] = useState(null);
    const [popoverSkill, setPopoverSkill] = useState(null);

    const skillCategories = skillsAssessmentResult.skills;
    const skillCategoryKeys = Object.keys(skillCategories).sort((a: string, b: string) => {
        const aCategory = skillsAssessmentResult.skills[a];
        const bCategory = skillsAssessmentResult.skills[b];

        if (aCategory.sortOrder === bCategory.sortOrder) return 0;
        else if (aCategory.sortOrder < bCategory.sortOrder) return -1;
        else return 1;
    });

    const handleSkillCategoryClicked = (value: string) => {
        if (props.onSkillCateogrySelected != null && selectedSkillCategory !== value) {
            props.onSkillCateogrySelected(value);
        }
    };

    const handleSkillClicked = (skill: any) => {
        if (props.onSkillSelected != null && selectedSkill !== skill) {
            let category = null;

            skillCategoryKeys.forEach(key => {
                if (skillCategories[key] && skillCategories[key].items.includes(skill)) {
                    category = key;
                }
            });

            props.onSkillCateogrySelected(category);
            props.onSkillSelected(skill);
        }
    };

    const handleSkillEntered = (event: any, skill: any) => {
        if (skill) {
            let skillCategory = null;

            skillCategoryKeys.forEach(key => {
                if (skillCategories[key] && skillCategories[key].items.includes(skill)) {
                    skillCategory = skillCategories[key];
                }
            });

            setPopoverSkill(skill);
            setPopoverSkillCategory(skillCategory);
            setPopoverAnchorEl(event.currentTarget);
        } else {
            setPopoverAnchorEl(null);
            setPopoverSkillCategory(null);
            setPopoverSkill(null);
        }
    };

    const handleSkillExited = (event?: any, skill?: any) => {
        if (popoverSkill === skill) {
            setPopoverAnchorEl(null);
            setPopoverSkillCategory(null);
            setPopoverSkill(null);
        }
    }

    const calculateOpacity = (score: number, total: number) => {
        const percentage = score / total;

        if (!Number.isNaN(score) || !Number.isNaN(total)) {
            if (percentage < 1) {
                return 0.76 - (0.76 * percentage);
            }
        }

        return 0;
    }

    const handleShowNonCriticalSkillsChanged = (event) => {
        if (props.onShowNonCriticalSkillsChanged != null) {
            props.onShowNonCriticalSkillsChanged(event.target.checked);
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                <Typography variant={'h6'} style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: 10 }}>Your Skills Map</Typography>
                <Typography style={{ flex: '1 1 auto', margin: 0, textAlign: 'left', maxWidth: 'unset', width: 'auto' }}>Select a skill to see what it is.</Typography>
                <Typography style={{ flex: '1 1 auto', margin: 0, textAlign: 'left', maxWidth: 'unset', width: 'auto' }}>Select the button above your "Skills Map" to eliminate skills that aren't related to your role.</Typography>
            </div>

            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', paddingLeft: 50, paddingTop: 30, marginBottom: 10 }}>
                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', width: 'auto', maxWidth: 'unset', margin: 0 }}>Skill Rating</Typography>

                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(1, 7)} label={'1'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(2, 7)} label={'2'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(3, 7)} label={'3'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(4, 7)} label={'4'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(5, 7)} label={'5'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(6, 7)} label={'6'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            <HexagonTile size={64} opacity={calculateOpacity(7, 7)} label={'7'} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                        </div>
                    </div>
                </div>

                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10 }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', width: 'auto', maxWidth: 'unset', margin: 0 }}>Critical Skills</Typography>

                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <HexagonTile size={64} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0.06} />
                    </div>
                </div>

                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10 }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', width: 'auto', maxWidth: 'unset', margin: 0 }}>Passed</Typography>

                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <AchievementAdornment size={64} backgroundColor={'#4389D0'} borderColor={'#2062A5'} color={'#FFFFFF'} strokePercentage={0} />
                    </div>
                </div>

                <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10, width: '200px' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', width: 'auto', maxWidth: 'unset', margin: 0, textAlign: 'center' }}>View Non-Required Skills</Typography>

                    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                        <CustomSwitch checked={showNonCriticalSkills} onChange={handleShowNonCriticalSkillsChanged} checkedIcon={<SwitchThumbIcon width={60} height={40} backgroundColor={'var(--tos-terms-of-service-button-hover-background-color)'} color={'var(--page-primary-font-color)'} />} />
                    </div>
                </div>
            </div>

            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'stretch', marginTop: 10, paddingTop: 20, borderTop: '2px solid var(--separator-color)' }}>
                <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'stretch', marginLeft: 'auto', marginRight: 'auto' }} >
                    {skillCategoryKeys.map((key, idx) => {
                        const skillCategory = skillCategories[key] as any;

                        const skillSets = [];
                        let currentSkillSet = null;
                        for (let x = 0; x < skillCategory.items.length; x++) {
                            const skill = skillCategory.items[x];

                            if (key === 'Hard') {
                                const first = x % 3 === 0;
                                const second = x % 3 === 1;
                                const third = x % 3 === 2;

                                if (first) {
                                    currentSkillSet = [];
                                    currentSkillSet.push(skill);

                                    if (x + 1 >= skillCategory.items.length) {
                                        skillSets.push(currentSkillSet);
                                        currentSkillSet = null;
                                    }
                                }

                                if (second) {
                                    currentSkillSet.push(skill);

                                    if (x + 1 >= skillCategory.items.length) {
                                        skillSets.push(currentSkillSet);
                                        currentSkillSet = null;
                                    }
                                }

                                if (third) {
                                    currentSkillSet.push(skill);
                                    skillSets.push(currentSkillSet);
                                    currentSkillSet = null;
                                }
                            } else {
                                const first = x % 2 === 0;
                                const second = x % 2 === 1;

                                if (first) {
                                    currentSkillSet = [];
                                    currentSkillSet.push(skill);

                                    if (x + 1 >= skillCategory.items.length) {
                                        skillSets.push(currentSkillSet);
                                        currentSkillSet = null;
                                    }
                                }

                                if (second) {
                                    currentSkillSet.push(skill);
                                    skillSets.push(currentSkillSet);
                                    currentSkillSet = null;
                                }
                            }
                        }

                        return (
                            <div key={idx} style={{ flex: '0 0 auto', display: 'flex', alignItems: 'stretch', maxWidth: key === 'Hard' ? 'unset' : '115px', marginLeft: key === 'Hard' ? 50 : 0 }}>
                                <div key={idx} style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: key === 'Hard' ? '130px' : '95px' }}>
                                    <div style={{ width: 64, height: 64, flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }} onClick={() => handleSkillCategoryClicked(key)}>
                                        <HexagonTile
                                            strokePercentage={0.06}
                                            size={selectedSkillCategory === key ? 64 : 48}
                                            backgroundColor={skillCategory.mainColour}
                                            borderColor={selectedSkillCategory === key ? '#808080' : skillCategory.borderColour}
                                            color={'#FFFFFF'}
                                        />

                                        {skillCategory.iconUrl != null &&
                                            <img style={{ position: 'absolute', zIndex: 1, top: selectedSkillCategory === key ? 16 : 20, left: selectedSkillCategory === key ? 16 : 20, width: selectedSkillCategory === key ? 32 : 24, height: selectedSkillCategory === key ? 32 : 24 }} src={skillCategory.iconUrl} />
                                        }
                                    </div>

                                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography noWrap={true} style={{ fontWeight: 'bold', maxWidth: 'unset', fontSize: '12px', width: '100%', margin: 0 }}>{key}</Typography>
                                        <Typography noWrap={true} style={{ fontWeight: 'bold', maxWidth: 'unset', fontSize: '12px', width: '100%', margin: 0 }}>Skills</Typography>
                                    </div>

                                    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative', height: (skillSets.length * 48) + 18, width: '100%', marginTop: 10 }}>
                                        {skillSets.map((skillSet, idx) => {
                                            const pairHeight = 66;
                                            const pairWidth = skillSet.length * 42;

                                            let top = idx * 43;

                                            const cellSize = 48;

                                            if (top < 0) top = 0;

                                            return (
                                                <div key={idx} style={{ height: pairHeight, width: pairWidth, flex: '1 1 auto', display: 'flex', position: 'absolute', top: top, pointerEvents: 'none' }}>
                                                    {skillSet[0] &&
                                                        <div style={{ position: 'absolute', top: 0, left: 0, width: cellSize, height: cellSize, flex: '0 0 auto', pointerEvents: 'none' }}>
                                                            <HexagonTile key={idx}
                                                                strokePercentage={skillSet[0] === selectedSkill ? 0.06 : skillSet[0].criticalSkill ? 0.06 : 0}
                                                                size={cellSize}
                                                                backgroundColor={skillCategory.mainColour}
                                                                borderColor={skillSet[0] === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                opacity={calculateOpacity(skillSet[0].userValue, 7)}
                                                                label={skillSet[0].displayShortName}
                                                                onClick={() => handleSkillClicked(skillSet[0])}
                                                                onMouseEnter={(e) => handleSkillEntered(e, skillSet[0])}
                                                                onMouseLeave={(e) => handleSkillExited(e, skillSet[0])}
                                                                adornment={(size: number, x: number, y: number) => {
                                                                    const skill = skillSet[0];

                                                                    if (skill.userValue >= skill.expectedRoleValue) {
                                                                        return <AchievementAdornment
                                                                            size={size}
                                                                            x={x}
                                                                            y={y}
                                                                            backgroundColor={skillCategory.mainColour}
                                                                            borderColor={skill === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                            color={'#FFFFFF'}
                                                                            strokePercentage={(skill.criticalSkill ? 0.06 : 0) * 2}
                                                                            opacity={calculateOpacity(skill.userValue, 7)}
                                                                        />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {skillSet[1] &&
                                                        <div style={{ position: 'absolute', top: 18, left: 38, width: cellSize, height: cellSize, flex: '0 0 auto', pointerEvents: 'none' }}>
                                                            <HexagonTile key={idx}
                                                                strokePercentage={skillSet[1] === selectedSkill ? 0.06 : skillSet[1].criticalSkill ? 0.06 : 0}
                                                                size={cellSize}
                                                                backgroundColor={skillCategory.mainColour}
                                                                borderColor={skillSet[1] === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                opacity={calculateOpacity(skillSet[1].userValue, 7)}
                                                                label={skillSet[1].displayShortName}
                                                                onClick={() => handleSkillClicked(skillSet[1])}
                                                                onMouseEnter={(e) => handleSkillEntered(e, skillSet[1])}
                                                                onMouseLeave={(e) => handleSkillExited(e, skillSet[1])}
                                                                adornment={(size: number, x: number, y: number) => {
                                                                    const skill = skillSet[1];

                                                                    if (skill.userValue >= skill.expectedRoleValue) {
                                                                        return <AchievementAdornment
                                                                            size={size}
                                                                            x={x}
                                                                            y={y}
                                                                            backgroundColor={skillCategory.mainColour}
                                                                            borderColor={skill === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                            color={'#FFFFFF'}
                                                                            strokePercentage={(skill.criticalSkill ? 0.06 : 0) * 2}
                                                                            opacity={calculateOpacity(skill.userValue, 7)}
                                                                        />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {skillSet[2] &&
                                                        <div style={{ position: 'absolute', top: 0, left: 76, width: cellSize, height: cellSize, flex: '0 0 auto', pointerEvents: 'none' }}>
                                                            <HexagonTile key={idx}
                                                                strokePercentage={skillSet[2] === selectedSkill ? 0.06 : skillSet[2].criticalSkill ? 0.06 : 0}
                                                                size={cellSize}
                                                                backgroundColor={skillCategory.mainColour}
                                                                borderColor={skillSet[2] === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                opacity={calculateOpacity(skillSet[2].userValue, 7)}
                                                                label={skillSet[2].displayShortName}
                                                                onClick={() => handleSkillClicked(skillSet[2])}
                                                                onMouseEnter={(e) => handleSkillEntered(e, skillSet[2])}
                                                                onMouseLeave={(e) => handleSkillExited(e, skillSet[2])}
                                                                adornment={(size: number, x: number, y: number) => {
                                                                    const skill = skillSet[2];

                                                                    if (skill.userValue >= skill.expectedRoleValue) {
                                                                        return <AchievementAdornment
                                                                            size={size}
                                                                            x={x}
                                                                            y={y}
                                                                            backgroundColor={skillCategory.mainColour}
                                                                            borderColor={skill === selectedSkill ? '#808080' : skillCategory.borderColour}
                                                                            color={'#FFFFFF'}
                                                                            strokePercentage={(skill.criticalSkill ? 0.06 : 0) * 2}
                                                                            opacity={calculateOpacity(skill.userValue, 7)}
                                                                        />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <Popover
                                        id={'skill-hover-popover'}
                                        className={classes.popover}
                                        open={popoverAnchorEl != null}
                                        anchorEl={popoverAnchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        onClose={handleSkillExited}
                                        style={{ pointerEvents: 'none' }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                                {popoverSkill &&
                                                    <MenuItem key={idx} className={classes.popoverSkill}>
                                                        <div className={classes.popoverIcon} style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 72, height: 72 }}>
                                                            <HexagonTile
                                                                strokePercentage={popoverSkill.criticalSkill ? 0.06 : 0}
                                                                size={64}
                                                                backgroundColor={popoverSkillCategory.mainColour}
                                                                borderColor={popoverSkillCategory.borderColour}
                                                                color={'#FFFFFF'}
                                                                label={popoverSkill.displayShortName}
                                                                adornment={(size: number, x: number, y: number) => {
                                                                    const skill = popoverSkill;

                                                                    if (skill.userValue >= skill.expectedRoleValue) {
                                                                        return <AchievementAdornment
                                                                            size={size}
                                                                            x={x}
                                                                            y={y}
                                                                            backgroundColor={popoverSkillCategory.mainColour}
                                                                            borderColor={popoverSkillCategory.borderColour}
                                                                            color={'#FFFFFF'}
                                                                            strokePercentage={(skill.criticalSkill ? 0.06 : 0) * 2}
                                                                        />;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ paddingLeft: 30, paddingRight: 5, flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden' }}>
                                                            <Typography style={{ flex: '1 1 auto', fontWeight: 'bold' }}>{popoverSkill.displayTitle}</Typography>
                                                            <Typography style={{ marginTop: 5 }}>{popoverSkill.displayDescription}</Typography>
                                                        </div>

                                                        <div style={{ paddingLeft: 10, flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', overflow: 'hidden', borderLeft: '2px solid white' }}>
                                                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
                                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: 'auto' }}>Your Score: </Typography>
                                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: '10px', color: popoverSkill.userValue >= popoverSkill.expectedRoleValue ? 'rgb(0, 255, 0)' : 'inherit' }}>{popoverSkill.userValue}</Typography>
                                                            </div>
                                                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
                                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: 'auto' }}>Target Score: </Typography>
                                                                <Typography style={{ flex: '0 0 auto', maxWidth: 'unset', width: '10px' }}>{popoverSkill.expectedRoleValue}</Typography>
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                }
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(YourSkillsMap);
