import {useDispatch, useSelector} from 'react-redux';
import AppConfigData from '../types/brainCloud/AppConfigData';
import { NO_PERMISSIONS_ERROR } from 'store/actions/auth';
import analytics from "analytics/analytics";

/**
 * Logs the user out of Runway, and returns them to the home screen
 *
 * We don't actually call brainCloud Logout, nor do we call Okta Logout. Neither is needed
 */
function useLogout() {
	const dispatch = useDispatch();

	const urlInputArguments = useSelector(
		(state: {appConfigData: AppConfigData}) => {
			return state.appConfigData.urlInputArguments;
		}
	);

	return () => {
		const isVr = localStorage.getItem("isVr") === 'true';
		const requestedTrainingModules = localStorage.getItem("requestedTrainingModules") ? localStorage.getItem("requestedTrainingModules").split(',') : [];
		const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined' ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null;

		localStorage.clear();
		localStorage.setItem('isVr', JSON.stringify(isVr));
		
		if (noPermissionsError) localStorage.setItem(NO_PERMISSIONS_ERROR, noPermissionsError);

		let redirect = isVr ? window.location.origin + '/vr/' : window.location.origin + '/';

		if (requestedTrainingModules && requestedTrainingModules.length > 0) {
			redirect = redirect + '?trainingModules=' + requestedTrainingModules;
		}

		// Analytics: 'User Logout' event tracking.
		analytics.track('User Logout', {
			category: 'Logout',
			name: 'User Logout',
		});
		analytics.reset();

		// console.log("Logout Redirect [VR Mode = " + isVr + "]", redirect, requestedTrainingModules);
		
		window.location.href = redirect;
	};
}

export default useLogout;
