import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {UserManager} from 'oidc-client';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import OuterBox from '../../../components/layout/OuterBox';
import InnerBox from '../../../components/layout/InnerBox';
import LoginButton from './LoginButton';
import PublicAppData from '../../../types/brainCloud/PublicAppData';
import AppConfigData from '../../../types/brainCloud/AppConfigData';
import {
	STARTING_AUTH,
	HANDOFF_TOKEN,
	NO_PERMISSIONS_ERROR,
} from '../../../store/actions/auth';

const Okta = () => {

	const useStyles = makeStyles(() => ({
		largeAvatar: {
			height: '96px',
			width: '96px',
			marginTop: '-32px',
			marginBottom: '32px',
		},
	}));
	
	const classes = useStyles();

	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.publicAppData
	);

	const isAuthing = useSelector(
		(state: {appConfigData: AppConfigData}) =>
			state?.appConfigData?.appState?.isAuthing
	);
	const dispatch = useDispatch();

	var userManager = null;

	if (publicAppData?.okta?.scopes) {
		var cleanedScopes = publicAppData.okta.scopes
			? publicAppData.okta.scopes.replace(/','/g, ' ')
			: 'openid email profile';

		userManager = new UserManager({
			authority: publicAppData.okta.issuer,
			client_id: publicAppData.okta.clientId,
			redirect_uri: `${window.location.origin}/implicit/callback`,
			response_type: 'token id_token',
			scope: cleanedScopes,
			// When CORS prevents discovery, we must use additional settings from the publicAppData.
			metadata: publicAppData?.okta?.authorization_endpoint
				? {
						issuer: publicAppData?.okta?.issuer,
						authorization_endpoint: publicAppData?.okta?.authorization_endpoint,
						userinfo_endpoint: publicAppData?.okta?.userinfo_endpoint,
						end_session_endpoint: publicAppData?.okta?.end_session_endpoint,
				  }
				: undefined,
		});
	}

	const handleLogin = () => {
		localStorage.removeItem(NO_PERMISSIONS_ERROR);
		localStorage.removeItem(HANDOFF_TOKEN);
		localStorage.removeItem('securityToken');
		localStorage.removeItem('expireSeconds');
		localStorage.removeItem('currentDate');
		localStorage.removeItem('expireDate');
		localStorage.removeItem('token');

		dispatch({
			type: STARTING_AUTH,
			payload: {},
		});

		// Check if dynamic discovery is being used
		if (userManager.settings.metadata) {
			console.log('Manual discovery is being used.');
		} else {
			console.log('Dynamic discovery (automatic) is being used.');
		}

		if (userManager) userManager.signinRedirect();
	};

	return (
		<OuterBox>
			<InnerBox>
				<Avatar src='/images/lock.svg'
				 	className={classes.largeAvatar}
					alt = 'lock'
				/>

				<LoginButton isSubmitting={isAuthing} onClick={handleLogin}>
					Log In
				</LoginButton>
			</InnerBox>
		</OuterBox>
	);
};

export default Okta;
