import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import AppConfigData from 'types/brainCloud/AppConfigData';
import { CHANGE_PAGE, createRequest } from '../../store/actions/auth';
import SkillsAssessmentState, { QuestionAnswers } from './SkillsAssessmentState';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import LongAnswers from './LongAnswers';
import QuestionImage from './QuestionImage';
import QuestionImageAndAnswerImages from './QuestionImageAndAnswerImages';
import QuestionImageAndAnswerButtons from './QuestionImageAndAnswerButtons';
import PrimarySearchAppBar from '../appbar';
import SurveyResults from './results';
import RatingAnswers from './RatingAnswers';

const sleep = (ms: number) => {
    return new Promise<void>((resolve, _reject) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

const SkillsAssessmentSurvey = props => {
    const dispatch = useDispatch();

    const appConfigData = useSelector((state: { appConfigData: AppConfigData }) => {
        return state.appConfigData;
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [showSoftIntroduction, setShowSoftIntroduction] = useState(false);
    const [showHardIntroduction, setShowHardIntroduction] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const [skillsAssessmentState, setSkillsAssessmentState] = useState(null as SkillsAssessmentState);
    const [showAnswers, setShowAnswers] = useState(true);
    const [currentAnswers, setCurrentAnswers] = useState({} as QuestionAnswers);
    const [skillsAssessmentResult, setSkillsAssessmentResult] = useState(null as any);

    const [questionStartTime, setQuestionStartTime] = useState(null);

    const lesson = skillsAssessmentState ? skillsAssessmentState.lesson : null;
    const lessonCompletion = skillsAssessmentState ? skillsAssessmentState.completionBar : null;
    const lessonCategory = lesson ? lesson.lessonCategory : null;
    const lessonDisplayType = lesson ? lesson.lessonDisplayType : null;
    const questionEvents = lesson ? lesson.questionEvents : null;

    useEffect(() => {
        setTimeout(() => {
            refreshSkillAssessmentStatus();
        }, 100);
    }, []);

    const isCurrentQuestionComplete = () => {
        if (!isLoading && !showSoftIntroduction && !showHardIntroduction && !showResults && questionEvents && showAnswers) {
            if (Object.keys(currentAnswers).length === questionEvents.length) {
                let isValid = true;

                Object.keys(currentAnswers).forEach(item => {
                    if (currentAnswers[item] == null) isValid = false;
                });

                return isValid;
            }
        }

        return false;
    }

    const isNextButtonEnabled = () => {
        if (!isLoading && (showSoftIntroduction || showHardIntroduction || showResults || !showAnswers || isCurrentQuestionComplete())) {
            return true;
        } else {
            return false;
        }
    }

    const refreshSkillAssessmentStatus = async () => {
        setIsLoading(true);

        await sleep(200);

        const statusRequest = createRequest('getStatusSkillsAssessmentSurvey', {});
        fetch(statusRequest).then(response => {
            if (response.status >= 200 && response.status < 300) return response.json();

            return Promise.reject('HTTP Status [' + response.status + ']: ' + response.statusText);
        }).then(async (json) => {
            if (json.data.success === false) {
                throw new Error(json.data.response);
            }

            console.debug("Skills Assessment Survey Status", json);

            const response = json && json.data ? json.data.response : null;

            if (response && response.nextQuestionResponse && response.nextQuestionResponse.errors && response.nextQuestionResponse.errors.length > 0) {
                setError(response.nextQuestionResponse.errors[0].errorMessage);
                return;
            }

            if (response) {
                const { hasAccess, completed, nextQuestionResponse } = response;

                if (!hasAccess) {
                    setError("Insuffciient Permissions");
                    return;
                }

                if (completed) {
                    await refreshCurrentSkillsAssessmentResult();
                    setShowResults(true);
                } else {
                    if (nextQuestionResponse) {
                        const { completionBar } = nextQuestionResponse;

                        let totalInProgress = false;
                        let totalComplete = false;

                        if (completionBar) {
                            Object.keys(completionBar).forEach(key => {
                                switch (key) {
                                    case 'total':
                                        if (completionBar[key] != null) {
                                            if (completionBar[key] > 0) {
                                                totalInProgress = completionBar[key] < 1 ? true : false;
                                                totalComplete = completionBar[key] >= 1;
                                            }
                                        }
                                        break;
                                    default:
                                        // Do nothing.
                                }
                            });
                        }

                        if (totalInProgress || totalComplete) {
                            await refreshCurrentSkillsAssessmentState();
                        } else {
                            setShowSoftIntroduction(true);
                        }
                    } else {
                        setShowSoftIntroduction(true);
                    }
                }
            }
        }).catch(error => {
            console.log("Failed To Fetch Skills Assessment Survey Status", error);

            setError(error);

            if (error && (error.includes('HTTP Status [401]') || error.includes('HTTP Status [403]'))) {
                setError(null);
                setSkillsAssessmentState(null);

                localStorage.clear();

                window.location.pathname = '/skillsAssessmentSurvey';
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const refreshCurrentSkillsAssessmentState = async () => {
        setIsLoading(true);
        setError(null);

        setSkillsAssessmentState(null);
        setShowSoftIntroduction(false);
        setShowAnswers(true);
        setCurrentAnswers({});

        await sleep(200);

        const nextQuestionRequest = createRequest('getNextQuestionSkillsAssessmentSurvey', {});
        await fetch(nextQuestionRequest).then(response => {
            if (response.status >= 200 && response.status < 300) return response.json();

            return Promise.reject('HTTP Status [' + response.status + ']: ' + response.statusText);
        }).then(async (json) => {
            if (json.data.success === false) {
                throw new Error(json.data.response);
            }

            console.debug("Skills Assessment Survey Next Question", json);

            const response = json && json.data ? json.data.response : null;

            if (response && response.errors && response.errors.length > 0) {
                setError(response.errors[0].errorMessage);
                return;
            }

            setSkillsAssessmentState(response);

            if (response && response.lesson && ['imageAndLongAnswerText'].includes(response.lesson.lessonDisplayType)) {
                setShowAnswers(false);
            }

            setQuestionStartTime(new Date());

            // Determine if the survey has already been started.
            if (response.isComplete) {
                await refreshCurrentSkillsAssessmentResult();
                setShowResults(true);
            } else if (response.completionBar) {
                const { completionBar } = response;

                let softInProgress = false;
                let softComplete = false;
                let hardInProgress = false;
                let hardComplete = false;
                let totalInProgress = false;
                let totalComplete = false;

                if (completionBar) {
                    Object.keys(completionBar).forEach(key => {
                        switch (key) {
                            case 'soft':
                                if (completionBar[key] != null) {
                                    if (completionBar[key] > 0) {
                                        softInProgress = completionBar[key] < 1 ? true : false;
                                        softComplete = completionBar[key] >= 1;
                                    }
                                }
                                break;
                            case 'hard':
                                if (completionBar[key] != null) {
                                    if (completionBar[key] > 0) {
                                        hardInProgress = completionBar[key] < 1 ? true : false;
                                        hardComplete = completionBar[key] >= 1;
                                    }
                                }
                                break;
                            case 'total':
                                if (completionBar[key] != null) {
                                    if (completionBar[key] > 0) {
                                        totalInProgress = completionBar[key] < 1 ? true : false;
                                        totalComplete = completionBar[key] >= 1;
                                    }
                                }
                                break;
                            default:
                                // Do nothing.
                        }
                    });
                }

                if (softComplete && !hardInProgress && !hardComplete) setShowHardIntroduction(true);
            } else {
                setShowSoftIntroduction(true);
            }
        }).catch(error => {
            console.log("Failed to fetch Skills Assessment Survey Question", error);

            setError(error);

            if (error && (error.includes('HTTP Status [401]') || error.includes('HTTP Status [403]'))) {
                setError(null);
                setSkillsAssessmentState(null);

                localStorage.clear();

                window.location.pathname = '/skillsAssessmentSurvey';
            }
        });

        setIsLoading(false);
    };

    const submitAnswers = async () => {
        if (!skillsAssessmentState || !lesson || !questionEvents || !currentAnswers) return;

        setIsLoading(true);

        const questionEndTime = new Date();
        const questionDuration = questionEndTime.getTime() - questionStartTime.getTime();

        const answerQuestionRequest = createRequest('scoreLessonProgress', {
            lessonId: lesson.lessonId,
            projectId: skillsAssessmentState.projectId,
            durationSec: questionDuration / 1000,
            tasks: questionEvents.map(item => {
                return {
                    eventId: item.eventId,
                    answerIndex: currentAnswers[item.eventId],
                    extension: {}
                };
            }),
            trackProgress: true
        });

        let submissionSuccessful = false;

        await fetch(answerQuestionRequest).then(response => {
            if (response.status >= 200 && response.status < 300) return response.json();

            return Promise.reject('HTTP Status [' + response.status + ']: ' + response.statusText);
        }).then(json => {
            if (json.data.success === false) {
                throw new Error(json.data.response);
            }

            console.debug("Skills Assessment Survey Answer Submission", json);

            const response = json && json.data ? json.data.response : null;

            if (response && response.errors && response.errors.length > 0) {
                setError(response.errors[0].errorMessage);
                return;
            }

            submissionSuccessful = true;

            setQuestionStartTime(null);
        }).catch(error => {
            console.log("Failed to submit Skills Assessment Survey Answer", error);

            setError(error);

            if (error && (error.includes('HTTP Status [401]') || error.includes('HTTP Status [403]'))) {
                setError(null);
                setSkillsAssessmentState(null);

                localStorage.clear();

                window.location.pathname = '/skillsAssessmentSurvey';
            }
        });

        if (submissionSuccessful) {
            await refreshCurrentSkillsAssessmentState();
        }

        setIsLoading(false);
    }

    const refreshCurrentSkillsAssessmentResult = async () => {
        setIsLoading(true);
        setError(null);

        setSkillsAssessmentResult(null);
        
        await sleep(200);

        const resultsRequest = createRequest('getYourSkillsChartSkillsAssessmentSurvey', {});

        await fetch(resultsRequest).then(response => {
            if (response.status >= 200 && response.status < 300) return response.json();

            return Promise.reject('HTTP Status [' + response.status + ']: ' + response.statusText);
        }).then(json => {
            if (json.data.success === false) {
                throw new Error(json.data.response);
            }

            console.debug("Skills Assessment SurveyResults", json);

            const response = json && json.data ? json.data.response : null;

            if (response && response.errors && response.errors.length > 0) {
                setError(response.errors[0].errorMessage);
                return;
            }

            setSkillsAssessmentResult(response);
        }).catch(error => {
            console.log("Failed to fetch Skills Assessment Survey Result", error);

            setError(error);
        });

        setIsLoading(false);
    };

    const handleNextClick = async () => {
        if (error) {
            await refreshCurrentSkillsAssessmentState();
        } else {
            if (showSoftIntroduction) {
                setShowSoftIntroduction(false);
                await refreshCurrentSkillsAssessmentState();
            } else {
                if (showHardIntroduction) {
                    setShowHardIntroduction(false);
                } else {
                    if (showResults) {
                        dispatch({ type: CHANGE_PAGE, payload: 'welcome' });
                    } else {
                        // TODO: Handle different display types.
                        if (showAnswers) await submitAnswers();
                        else setShowAnswers(true);
                    }
                }
            }
        }
    };

    const handleAnswerSelected = (eventId: string, answerIndex: number) => {
        const updatedCurrentAnswers = Object.assign({}, currentAnswers);
        updatedCurrentAnswers[eventId] = answerIndex;

        setCurrentAnswers(updatedCurrentAnswers);
    };

    const renderLoading = () => {
        return (
            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />

                <Typography style={{ marginTop: 10, marginBottom: 0, marginLeft: 0, marginRight: 0 }}>Please wait...</Typography>
            </div>
        );
    }

    const renderError = () => {
        return (
            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography style={{ flex: '0 0 auto' }}>{error ? error : 'Error loading survey data'}</Typography>

                <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', margin: 10 }} onClick={handleNextClick}>{'Retry'}</Button>
            </div>
        );
    }

    const renderSoftIntroduction = () => {
        const skillsIcon = <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 211" style={{ width: '100%', height: '100%' }}>
            <defs>
                <style>{'.cls-1{fill:none;}.cls-2{fill:var(--tos-continue-button-background-color);}'}</style>
            </defs>
            <g id="Group_1477" data-name="Group 1477">
                <g id="Polygon_32" data-name="Polygon 32">
                    <path className="cls-1" d="M87.38,109.58l29.13,50.63L87.38,210.85H29.13L0,160.21l29.13-50.63Z" />
                    <path className="cls-2" d="M34.91,119.58,11.54,160.21l23.37,40.64H81.6L105,160.21,81.6,119.58H34.91m-5.78-10H87.38l29.13,50.63L87.38,210.85H29.13L0,160.21Z" />
                </g>
                <g id="Polygon_33" data-name="Polygon 33">
                    <path className="cls-1" d="M87.38.15l29.13,50.64L87.38,101.42H29.13L0,50.79,29.13.15Z" />
                    <path className="cls-2" d="M34.91,10.15,11.54,50.79,34.91,91.42H81.6L105,50.79,81.6,10.15H34.91M29.13.15H87.38l29.13,50.64L87.38,101.42H29.13L0,50.79Z" />
                </g>
                <g id="Polygon_34-2" data-name="Polygon 34-2">
                    <path className="cls-1" d="M180.87,54.57,210,105.2l-29.13,50.64H122.62L93.49,105.2l29.13-50.63Z" />
                    <path className="cls-2" d="M128.4,64.57,105,105.2l23.38,40.64h46.69l23.37-40.64L175.09,64.57H128.4m-5.78-10h58.25L210,105.2l-29.13,50.64H122.62L93.49,105.2Z" />
                </g>
            </g>
        </svg>;

        return (
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', maxWidth: '80%' }}>
                <div style={{ flex: '0 0 auto', width: '200px', height: '200px' }}>
                    {skillsIcon}
                </div>

                <div style={{ flex: '0 0 auto', marginTop: 30 }}>
                    <Typography style={{ width: 'auto', maxWidth: 'unset', fontSize: '64px', fontWeight: 'bold', lineHeight: 'unset' }}>Discover Your Skills</Typography>
                </div>

                <div style={{ flex: '0 0 auto', marginTop: 30 }}>
                    <Typography style={{ width: 'auto', maxWidth: 'unset', fontSize: '32px', lineHeight: 'unset' }}>Click to Begin</Typography>
                </div>

                <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', marginTop: 30 }} onClick={handleNextClick}>
                    {'START'}
                </Button>
            </div>
        );
    }

    const renderHardIntroduction = () => {
        const skillsIcon = <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 211" style={{ width: '100%', height: '100%' }}>
            <defs>
                <style>{'.cls-1{fill:none;}.cls-2{fill:var(--tos-continue-button-background-color);}'}</style>
            </defs>
            <g id="Group_1477" data-name="Group 1477">
                <g id="Polygon_32" data-name="Polygon 32">
                    <path className="cls-1" d="M87.38,109.58l29.13,50.63L87.38,210.85H29.13L0,160.21l29.13-50.63Z" />
                    <path className="cls-2" d="M34.91,119.58,11.54,160.21l23.37,40.64H81.6L105,160.21,81.6,119.58H34.91m-5.78-10H87.38l29.13,50.63L87.38,210.85H29.13L0,160.21Z" />
                </g>
                <g id="Polygon_33" data-name="Polygon 33">
                    <path className="cls-1" d="M87.38.15l29.13,50.64L87.38,101.42H29.13L0,50.79,29.13.15Z" />
                    <path className="cls-2" d="M34.91,10.15,11.54,50.79,34.91,91.42H81.6L105,50.79,81.6,10.15H34.91M29.13.15H87.38l29.13,50.64L87.38,101.42H29.13L0,50.79Z" />
                </g>
                <g id="Polygon_34-2" data-name="Polygon 34-2">
                    <path className="cls-1" d="M180.87,54.57,210,105.2l-29.13,50.64H122.62L93.49,105.2l29.13-50.63Z" />
                    <path className="cls-2" d="M128.4,64.57,105,105.2l23.38,40.64h46.69l23.37-40.64L175.09,64.57H128.4m-5.78-10h58.25L210,105.2l-29.13,50.64H122.62L93.49,105.2Z" />
                </g>
            </g>
        </svg>;

        return (
            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', maxWidth: '80%' }}>
                <div style={{ flex: '0 0 auto', width: '200px', height: '200px' }}>
                    {skillsIcon}
                </div>

                <div style={{ flex: '0 0 auto', marginTop: 30 }}>
                    <Typography style={{ width: 'auto', maxWidth: 'unset', fontSize: '32px', lineHeight: 'unset' }}>You just completed the first section of our survey. In this last section, we'll ask you questions specific to your job role. Once you finish, we'll show you your results!</Typography>
                </div>

                <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', marginTop: 30 }} onClick={handleNextClick}>
                    {'CONTINUE'}
                </Button>
            </div>
        );
    }

    const renderLesson = () => {
        if (lesson && questionEvents && questionEvents.length > 0 && questionEvents[0]) {
            if (lessonCategory === 'soft') {
                const questionEvent = questionEvents[0];

                switch (lessonDisplayType) {
                    case 'imageAndAnswerImages':
                        return <QuestionImageAndAnswerImages questionEvent={lesson.questionEvents[0]} onAnswerSelected={handleAnswerSelected} selected={currentAnswers[questionEvent.eventId]} />;
                    case 'imageAndLongAnswerText':
                        if (showAnswers) return <LongAnswers questionEvent={lesson.questionEvents[0]} onAnswerSelected={handleAnswerSelected} selected={currentAnswers[questionEvent.eventId]} />;
                        else return <QuestionImage questionEvent={lesson.questionEvents[0]} />;
                    case 'imageAndShortAnswerText':
                        return <QuestionImageAndAnswerButtons questionEvent={lesson.questionEvents[0]} onAnswerSelected={handleAnswerSelected} selected={currentAnswers[questionEvent.eventId]} />;
                    case 'longAnswerText':
                        return <LongAnswers questionEvent={lesson.questionEvents[0]} onAnswerSelected={handleAnswerSelected} selected={currentAnswers[questionEvent.eventId]} />;
                    default:
                        return (
                            <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography>Unknown Lesson Display Type: {lessonDisplayType}</Typography>
                            </div>
                        );
                }
            } else if (lessonCategory === 'hard') {
                return (
                    <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%', marginTop: 'auto', marginBottom: 'auto' }}>
                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Typography style={{ lineHeight: 'unset', width: 'auto', maxWidth: 'unset', margin: 0, fontWeight: 'bold', fontSize: '2rem', textAlign: 'left' }}>{lesson.questionTitle}</Typography>
                            <Typography style={{ lineHeight: 'unset', width: 'auto', maxWidth: 'unset', marginTop: 10, marginBottom: 0, marginLeft: 0, marginRight: 0, fontSize: '1.5rem', textAlign: 'left' }}>{lesson.questionDescription}</Typography>
                        </div>

                        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}>
                            {lesson.questionEvents.map((questionEvent, idx) => {
                                return <RatingAnswers key={idx} questionEvent={questionEvent} onAnswerSelected={handleAnswerSelected} selected={currentAnswers[questionEvent.eventId]} />;
                            })}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <Typography>Unknown Lesson Category: {lessonCategory}</Typography>
                    </div>
                );
            }
        } else {
            return null;
        }
    }

    const renderResults = () => {
        return skillsAssessmentResult ? <SurveyResults skillsAssessmentResult={skillsAssessmentResult} /> : null;
    }

    const hasNext = () => {
        if (showSoftIntroduction || showHardIntroduction || (!showResults && (!skillsAssessmentState || !skillsAssessmentState.isComplete))) return true;
        else return false;
    }

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <PrimarySearchAppBar />

            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', marginTop: '64px', marginBottom: '64px', height: 'calc(100% - 128px)', width: '100%', overflow: 'hidden' }}>
                <DialogContent style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'stretch', overflow: 'auto', width: '100%', height: '100%', padding: 0 }}>
                    {isLoading && renderLoading()}

                    {!isLoading && error && renderError()}

                    {!isLoading && !error && showSoftIntroduction && renderSoftIntroduction()}

                    {!isLoading && !error && !showSoftIntroduction && showHardIntroduction && renderHardIntroduction()}

                    {!isLoading && !error && !showSoftIntroduction && !showHardIntroduction && !showResults && skillsAssessmentState && renderLesson()}

                    {!isLoading && !error && showResults && renderResults()}
                </DialogContent>

                {!isLoading &&
                    <DialogActions style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: '10%', marginRight: '10%', flexWrap: 'wrap' }} disableSpacing={true}>
                        {!showSoftIntroduction && !showHardIntroduction && !showResults && lessonCompletion && lessonCompletion[lessonCategory] != null &&
                            <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography style={{ flex: '0 0 auto', width: 'auto' }}>{lesson.progressLabel ? lesson.progressLabel : 'Progress:'}</Typography>

                                <LinearProgress style={{ flex: '1 1 auto' }} variant="determinate" value={lessonCompletion['total'] * 100} />
                            </div>
                        }

                        {(!showResults && !showSoftIntroduction && !showHardIntroduction && skillsAssessmentState) &&
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', margin: 10 }} disabled={!isNextButtonEnabled()} onClick={handleNextClick}>
                                    {hasNext() ? 'Next' : 'Dismiss'}
                                </Button>

                                {/* {!showResults &&
                                    <Button id={'survey-button'} variant="contained" style={{ flex: '0 0 auto', margin: 10 }} onClick={handleResultsClick}>
                                        {'Results'}
                                    </Button>
                                } */}
                            </div>
                        }
                    </DialogActions>
                }
            </div>
        </div>
    );
};

export default SkillsAssessmentSurvey;
