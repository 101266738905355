import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PublicAppData from '../../types/brainCloud/PublicAppData';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import {Field, Form} from 'react-final-form';
import FieldLayout from './form/FieldLayout';
import TextField from '@material-ui/core/TextField';
import LoginButton from './form/LoginButton';
import OuterBox from '../../components/layout/OuterBox';
import PrimarySearchAppBar from '../appbar';
import {CHANGE_PAGE} from '../../store/actions/auth';
import ErrorSymbol from 'components/text/ErrorSymbol';
import AuthType from 'types/enums/AuthType';
import { Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ForgotPasswordPage = (props) => {
	const publicAppData: PublicAppData = useSelector(
		(state: {appConfigData: AppConfigData}) => {
			return state.appConfigData.publicAppData;
		}
	);

	const dispatch = useDispatch();

	const [mode, setMode] = useState('resetting');

	const [emailSentTo, setEmailSentTo] = useState();

	const handleForgotPassword = (values) => {
		if (!values || !values.externalId) {
			return;
		}

		setMode('loading');

		const externalId = values.externalId;
		setEmailSentTo(externalId);

		const url = publicAppData?.emailAuth?.resetPassword?.hook;
		const secret = publicAppData?.emailAuth?.resetPassword?.secret;

		const body = publicAppData?.authType === AuthType.Email ? JSON.stringify({ email: externalId }) : JSON.stringify({ username: externalId });

		fetch(url, {
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'x-bc-secret': secret},
			body: body,
		}).then((res) => {
			if (res) {
				if (res.status === 200) {
					setMode('success');
				} else {
					setMode('failure');
				}
			}
		});
	};

	if (mode === 'success') {
		return (
			<OuterBox>
				<div style={{ width: '300px', margin: 'auto', alignSelf: 'center', justifySelf: 'center' }}>
					{publicAppData?.authType === AuthType.Universal &&
						<div style={{ marginBottom: '64px', fontSize: '18px' }}>
							<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset' }}>Password reset email sent.</Typography>
							<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset' }}>Contact your administrator to help you reset your password if you did not receive a password reset email.</Typography>
						</div>
					}

					{publicAppData?.authType === AuthType.Email &&
						<div style={{ marginBottom: '64px', fontSize: '18px' }}>
							<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset' }}>If a matching account was found an email was sent to </Typography>
							<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset' }}>
								<span style={{ color: 'var(--page-secondary-font-color)', fontFamily: 'Helvetica Now Bold' }}>{emailSentTo}</span>
							</Typography>
							<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset' }}> to allow you to reset your password.</Typography>
						</div>
					}

					<LoginButton
						onClick={(e) => {
							e.preventDefault();
							dispatch({type: CHANGE_PAGE, payload: 'login'});
						}}
					>Done</LoginButton>
				</div>

				<PrimarySearchAppBar />
			</OuterBox>
		);
	}

	return (
		<OuterBox>
			<div style={{width: '300px', margin: 'auto', alignSelf: 'center', justifySelf: 'center'}}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '48px', height: '48px', position: 'absolute', left: 20, top: 6 }} onClick={(e) => {
					e.preventDefault();
					dispatch({type: CHANGE_PAGE, payload: 'login'});
				}}>
					<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
						<ArrowBackIosIcon style={{ color: 'var(--navigation-icon-color)' }} />
					</div>
				</div>

				<Form
					subscription={{submitFailed: true}}
					onSubmit={handleForgotPassword}
					validate={(values) => {
						let errors: any = {};
						if (
							!values.externalId ||
							(publicAppData?.authType === AuthType.Email && !values.externalId.match(
								/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
							))
						) {
							errors.externalId = 'Enter your email';
						}
						return errors;
					}}
				>
					{({handleSubmit, submitting}) => {
						return (
							<form onSubmit={() => handleSubmit()}>
								<Field name='externalId'>
									{({input, meta}) => (
										<FieldLayout>
											{publicAppData?.authType === AuthType.Universal &&
												<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset', whiteSpace: 'nowrap', textAlign: 'center' }}>Enter your login username</Typography>
											}
											{publicAppData?.authType === AuthType.Email &&
												<Typography style={{ margin: 0, width: '100%', maxWidth: 'unset', whiteSpace: 'nowrap', textAlign: 'center' }}>Enter your login email address</Typography>
											}

											{publicAppData?.authType === AuthType.Universal &&
												<TextField
													id='username'
													variant='outlined'
													fullWidth
													autoFocus={true}
													{...input}
													error={meta.error && meta.submitFailed}
												/>
											}
											{publicAppData?.authType === AuthType.Email &&
												<TextField
													id='email'
													variant='outlined'
													fullWidth
													autoFocus={true}
													{...input}
													error={meta.error && meta.submitFailed}
												/>
											}

											{meta.error && meta.submitFailed && <ErrorSymbol message={meta.error} />}
										</FieldLayout>
									)}
								</Field>

								<LoginButton
									onClick={(e) => {
										e.preventDefault();

										handleSubmit();
									}}
								>
									Send Reset Email
								</LoginButton>
							</form>
						);
					}}
				</Form>
			</div>
			<PrimarySearchAppBar />
		</OuterBox>
	);
};

export default ForgotPasswordPage;
