import React from 'react';

import get from 'lodash/get';

export function HeaderLogo({appConfigData}) {
	const mainLogo = get(
		appConfigData,
		'publicAppData.images.passportPostLoginLogo'
	);

	if (!mainLogo) {
		return <React.Fragment></React.Fragment>;
	}

	return (
		<img
			style={{
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '40px',
				marginBottom: '10px',
				width: '234px',
				//height: '53px',
				display: 'block',
			}}
			src={mainLogo}
		/>
	);
}
