import {BC_URI, project, requestingIP} from 'utils';

import AppConfigData from 'types/brainCloud/AppConfigData';
import HandoffToken from 'types/brainCloud/HandoffToken';
import PublicAppData from 'types/brainCloud/PublicAppData';
import { SET_BRANDING_CSS_DATA } from '../branding';

export const LOADING_STATE_CHANGE = 'LOADING_STATE_CHANGE';

export const RESTORE_CACHE = 'RESTORE_CACHE';

export const GET_PUBLIC_CONFIG = 'GET_PUBLIC_CONFIG';
export const GET_PRIVATE_CONFIG = 'GET_PRIVATE_CONFIG';

export const STARTING_AUTH = 'STARTING_AUTH';
export const FINISHING_AUTH = 'FINISHING_AUTH';
export const ADD_AUTH_PAYLOAD = 'ADD_AUTH_PAYLOAD';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_REMOVE_ERROR = 'AUTH_REMOVE_ERROR';

export const OKTA_AUTH = 'OKTA_AUTH';
export const EMAIL_AUTH = 'EMAIL_AUTH';

export const REFRESH_AUTH = 'REFRESH_AUTH';
export const HANDOFF_TOKEN = 'HANDOFF_TOKEN';
export const LOGOUT = 'LOGOUT';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const NO_PERMISSIONS_ERROR = 'NO_PERMISSIONS_ERROR';

export const ADD_OUTPUT_SEARCH_PARAMS = 'ADD_OUTPUT_SEARCH_PARAMS';
export const ADD_INPUT_SEARCH_PARAMS = 'ADD_INPUT_SEARCH_PARAMS';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const DISMISS_SYSTEM_MESSAGE = 'DISMISS_SYSTEM_MESSAGE';

/**
 * Gets the public brainCloud config
 */
export const restoreCache = (callback?) => {
	var payload: AppConfigData = {};

	try {
		payload.appState = {isAuthing: false};
		payload.handOffToken = JSON.parse(localStorage.getItem(HANDOFF_TOKEN));
		payload.publicAppData = JSON.parse(localStorage.getItem(GET_PUBLIC_CONFIG));
		payload.sessionToken = JSON.parse(localStorage.getItem(ADD_AUTH_PAYLOAD));
		payload.urlOutputArguments = JSON.parse(
			localStorage.getItem(ADD_OUTPUT_SEARCH_PARAMS)
		);

		payload.urlInputArguments = JSON.parse(
			localStorage.getItem(ADD_INPUT_SEARCH_PARAMS)
		);

		payload.hasPermissionError = localStorage.getItem(NO_PERMISSIONS_ERROR);
	} catch (e) {}
	return {
		type: RESTORE_CACHE,
		payload: payload,
		callback,
	};
};

/**
 * Gets the public brainCloud config
 */
export const getPublicConfig = (callback?) => {
	return (dispatch, getState) => {
		const currentState = getState();
		const currentUrlParams = currentState.appConfigData ? currentState.appConfigData.urlInputArguments : null;
		// console.log("Current URL Params", currentUrlParams);

		const options = {
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
			method: 'POST',
			body: JSON.stringify({
				project,
				requestingIP,
			}),
		};

		return fetch(BC_URI.getPublicAppConfig, options)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				var payload: PublicAppData = json;

				localStorage.setItem(GET_PUBLIC_CONFIG, JSON.stringify(payload));

				if (payload.branding) {
					let brandingCSSData = {};
					if (payload.branding['__DEFAULT__']) {
						// console.log("Applying Organization Branding", (payload as any).orgId);

						// Get the org-specific default branding data.
						brandingCSSData = payload.branding['__DEFAULT__'];
					}

					// Check for app-specific branding data.
					let targetTrainingModule = null;
					const rawTrainingModules = currentUrlParams ? currentUrlParams.trainingModules : null;
					const targetTrainingModules = rawTrainingModules ? rawTrainingModules.split(',') : [];
					if (targetTrainingModules.length > 0) {
						targetTrainingModule = targetTrainingModules[0];
					}

					// If we have valid app-specific branding data, then apply it on top of the org-specific branding.
					if (targetTrainingModule) {
						// console.log("Applying Module/App Branding", targetTrainingModule);
						brandingCSSData = Object.assign(brandingCSSData, payload.branding[targetTrainingModule]);
					}

					if (brandingCSSData) {
						dispatch({ type: SET_BRANDING_CSS_DATA, payload: brandingCSSData });
					}
				}

				return dispatch({
					type: GET_PUBLIC_CONFIG,
					payload: payload,
					callback,
				});
			});
	};
};

export const removeError = (callback?) => {
	return (dispatch) =>
		dispatch({
			type: AUTH_REMOVE_ERROR,
			payload: {},
			callback,
		});
};

export function createRequest(scriptName: string, scriptData: {}) {
	const packetId = parseInt(localStorage.getItem('packetId')) + 1;

	const token = localStorage.getItem('token');

	localStorage.setItem('packetId', packetId.toString());

	var headers = new Headers();
	headers.append('Content-Type', 'application/json');
	headers.append('X-PROJECT', project);
	headers.append('Authorization', `Bearer ${token}`);
	headers.append('X-PACKETID', packetId.toString());

	const request = new Request(BC_URI.postScript, {
		method: 'POST',
		body: JSON.stringify({scriptName, scriptData}),
		headers,
	});
	return request;
}

/**
 * Gets the public brainCloud config
 */
export const generatePin = (scriptData: {}, callback?) => {
	return (dispatch) => {
		var scriptName = 'createHandoffToken';
		const request = createRequest(scriptName, scriptData);

		return fetch(request)
			.then((response) => {
				if (response.status !== 200) {
					return;
				}

				return response.json();
			})
			.then((json) => {
				if (
					!json ||
					!json.data ||
					!json.data.response ||
					!json.data.response.securityToken
				) {
					localStorage.removeItem(HANDOFF_TOKEN);
					localStorage.removeItem('securityToken');
					localStorage.removeItem('expireSeconds');
					localStorage.removeItem('currentDate');
					localStorage.removeItem('expireDate');

					// console.log("Redirecting to login... missing security token");

					const requestTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];
					if (requestTrainingModules.length > 0) {
						window.location.href = window.location.origin + '/?trainingModules=' + requestTrainingModules.join(',');
					} else {
						window.location.href = window.location.origin + '/';
					}

					return;
				}

				var payload: HandoffToken = json.data.response;

				localStorage.setItem(HANDOFF_TOKEN, JSON.stringify(payload));
				localStorage.setItem('securityToken', payload.securityToken);
				localStorage.setItem('expireSeconds', payload.expireSeconds.toString());
				localStorage.setItem('currentDate', payload.currentDate.toString());
				localStorage.setItem('expireDate', payload.expireDate.toString());

				dispatch({
					type: HANDOFF_TOKEN,
					payload: payload,
					callback,
				});
			});
	};
};
