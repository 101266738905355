import React from 'react';

import Button from '@material-ui/core/Button';
import useLogout from 'hooks/useLogout';

export function LogoutButton() {
	const logout = useLogout();

	return (
		<Button
			id={'logout-button'}
			style={{
				marginTop: 'auto',
				marginBottom: 60,
				fontSize: '18px',
				fontFamily: 'Helvetica Now Bold',
				minHeight: 51,
			}}
			onClick={logout}
		>
			Log Out
		</Button>
	);
}
