import React from 'react';

interface PROPS {
    size: number,
    x?: number,
    y?: number,
    backgroundColor: string,
    borderColor: string,
    color: string,
    label?: string,
    strokePercentage?: number
}

const StackCountAdornment = (props: PROPS) => {
    const { size, x, y, backgroundColor, borderColor, color, label, strokePercentage } = props;

    const realWidth = 24;
    const realHeight = 24;

    const radius = 23.48 * 0.45;

    const strokeWidth = strokePercentage != null ? strokePercentage * realWidth : 0.07 * realWidth;

    return (
        <svg x={x} y={y} width={size} height={size} viewBox={"0 0 " + realWidth + " " + realHeight} xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ pointerEvents: 'none' }}>
            {/* Primary Adornment Shape */}
            <circle cx={12} cy={12} r={radius} fill={backgroundColor} stroke={borderColor} strokeWidth={strokeWidth} />

            {/* Label */}
            <text textAnchor="middle" alignmentBaseline="middle" x={realHeight / 2} y={realHeight * 0.55} style={{ fontFamily: 'Arial', fontWeight: 'bold', fill: color, stroke: color, fontSize: realHeight * 0.6 }}>{label}</text>
        </svg>
    );
};

export default StackCountAdornment;
