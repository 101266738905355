import React from 'react';

import Button from '@material-ui/core/Button';
import {CircularProgress} from '@material-ui/core';

const LoginButton = (props: {
	isSubmitting?: boolean;
	onClick: (e: any) => void;
	children: any;
}) => {
	return (
		<Button
			id={'login-button'}
			disabled={props.isSubmitting}
			variant='contained'
			onClick={props.onClick}
			type={'submit'}
			style={{ fontSize: '18px', fontFamily: 'Helvetica Now Bold' }}
		>
			{props.isSubmitting && (
				<CircularProgress
					style={{
						left: '-30px',
						width: '18px',
						marginRight: '-18px',
						position: 'relative'
					}}
					size={18}
					thickness={2}
				/>
			)}
			{props.children}
		</Button>
	);
};

export default LoginButton;
