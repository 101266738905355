import {
	SET_BRANDING_CSS_DATA,
} from 'store/actions/branding';
import { BrandingCSSData } from 'types/brainCloud/BrandingData'

const initialState: BrandingCSSData = {
	'page-background-gradient-start': '#292C3C',
	'page-background-gradient-end': '#292C3C',
	'page-primary-font-color': '#FFFFFF',
	'page-secondary-font-color': '#FFFFFF',

	'error-background-gradient-start': '#FF0000',
	'error-background-gradient-end': '#FF0000',
	'error-font-color': '#FFFFFF',

	'footer-background-gradient-start': '#292C3C',
	'footer-background-gradient-end': '#292C3C',
	'footer-font-color': '#FFFFFF',

	'input-control-background-color': '#131626',
	'input-control-font-color': '#FFFFFF',
	'input-control-active-border-color': '#FFC331',

	'login-button-background-color': '#FFC331',
	'login-button-font-color': '#292C3C',
	'login-button-hover-background-color': '#FFC331',

	'generate-pin-button-background-color': '#FFC331',
	'generate-pin-button-font-color': '#292C3C',
	'generate-pin-button-hover-background-color': '#FFC331',

	'refresh-pin-button-background-color': '#FFC331',
	'refresh-pin-button-font-color': '#292C3C',
	'refresh-pin-button-hover-background-color': '#FFC331',

	'logout-button-background-color': 'FFC331',
	'logout-button-font-color': '#292C3C',
	'logout-button-hover-background-color': 'FFC331',

	'tos-terms-of-service-button-background-color': '#FFC331',
	'tos-terms-of-service-button-font-color': '#292C3C',
	'tos-terms-of-service-button-hover-background-color': '#FFC331',

	'tos-privacy-policy-button-background-color': '#FFC331',
	'tos-privacy-policy-button-font-color': '#292C3C',
	'tos-privacy-policy-button-hover-background-color': '#FFC331',

	'tos-continue-button-background-color': '#FFC331',
	'tos-continue-button-font-color': '#292C3C',
	'tos-continue-button-hover-background-color': '#FFC331',

	'navigation-background-color': '#131626',
	'navigation-font-color': '#FFFFFF',
	'navigation-icon-color': '#FFFFFF',

	'pin-letter-font-color': '#FFFFFF',
	'pin-number-font-color': '#FFC331',
	'pin-expiration-font-color': '#EF3F45',

	'footer-logo-color': '#292C3C',

	'separator-color': '#FFFFFF'
};

const BrandingReducer = (state = initialState, action: any) => {
	let newState = Object.assign({}, state);

	const type = action.type;
	const payload = action.payload;

	if (type === SET_BRANDING_CSS_DATA) {
        if (payload) {
            newState = Object.assign(initialState, payload);
        } else {
            newState = Object.assign({}, initialState);
        }
	}

	return newState;
};

export default BrandingReducer;
