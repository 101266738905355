import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';

const ErrorSymbol = props => {
	return (
		<div
			style={{
				color: 'red',
				width: '20px',
				height: '20px',
				backgroundColor: '#E2E2E1',
				borderRadius: 100,
				position: 'relative',
				left: '330px',
				top: '-50px'
			}}
		>
			<div style={{ position: 'relative', left: '-10px', top: '-10px' }}>
				{props.message &&
					<Tooltip title={props.message} aria-label="error-message">
						<ErrorIcon fontSize={'large'} />
					</Tooltip>
				}
				{!props.message &&
					<ErrorIcon fontSize={'large'} />
				}
			</div>
		</div>
	);
};

export default ErrorSymbol;
