import React, { ReactNode } from 'react';

interface PROPS {
    pointyTop?: boolean,
    size: number,
    backgroundColor: string,
    borderColor: string,
    color?: string,
    label?: string,
    strokePercentage?: number,
    opacity?: number,
    adornment?: (size: number, x: number, y: number) => ReactNode,
    onClick?: (event?: any) => void,
    onMouseEnter?: (event?: any) => void,
    onMouseLeave?: (event?: any) => void
}

const HexagonTile = (props: PROPS) => {
    const { pointyTop, size, backgroundColor, borderColor, color, label, strokePercentage, opacity, adornment } = props;

    const outerWidth = adornment != null ? 220 : 200;
    const outerHeight = adornment != null ? 220 : 200;

    const hexWidth = 200;
    const hexHeight = 200;
    const hexRadius = 90;

    const realFillColor = backgroundColor != null ? backgroundColor : 'lightgrey';
    const realStrokeColor = borderColor != null ? borderColor : 'grey';
    const realStrokeWidth = strokePercentage != null ? strokePercentage * hexWidth : 0.07 * hexWidth;

    const hexagonPoints = [0, 1, 2, 3, 4, 5, 6].map((n, i) => {
        var angle_deg = pointyTop ? 60 * i - 30 : 60 * i;
        var angle_rad = Math.PI / 180 * angle_deg;
        return [hexWidth / 2 + hexRadius * Math.cos(angle_rad), hexHeight / 2 + hexRadius * Math.sin(angle_rad)];
    }).map((p) => p.join(',')).join(' ');

    const hexX = (outerWidth - hexWidth) / 2;
    const hexY = (outerHeight - hexHeight) / 2

    const adornmentX = hexX + 115;
    const adornmentY = hexY - (realStrokeWidth / 2) + 5;

    return (
        <svg width={size != null ? size : '38px'} height={size != null ? size : '38px'} viewBox={"0 0 " + outerWidth + " " + outerHeight} xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ pointerEvents: 'none' }}>
            {/* Primary Interactive Hex */}
            <svg x={hexX} y={hexY} width={hexWidth} height={hexHeight} viewBox={"0 0 " + hexWidth + " " + hexHeight} xmlns="http://www.w3.org/2000/svg" version="1.1">
                <polygon style={{ fill: realFillColor, stroke: realStrokeColor, strokeWidth: realStrokeWidth, pointerEvents: 'auto' }} points={hexagonPoints} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} />
            </svg>

            {/* Optional Opacity */}
            {opacity != null &&
                <svg x={hexX} y={hexY} width={hexWidth} height={hexHeight} viewBox={"0 0 " + hexWidth + " " + hexHeight} xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <polygon style={{ fill: '#FFFFFF', stroke: realStrokeColor, strokeWidth: realStrokeWidth, fillOpacity: opacity, pointerEvents: 'auto' }} points={hexagonPoints} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} />
                </svg>
            }

            {/* Optional Label */}
            {label != null &&
                <text textAnchor="middle" alignmentBaseline="middle" x={outerWidth * 0.5} y={outerHeight * 0.55} style={{ fontFamily: 'Arial', fontWeight: 'bold', fill: color ? color : '#FFFFFF', stroke: color ? color : '#FFFFFF', fontSize: hexHeight * 0.3 }}>{label}</text>
            }

            {/* Optional Adornment */}
            {adornment != null &&
                adornment(75, adornmentX - 15, adornmentY)
            }
        </svg>
    );
};

export default HexagonTile;
