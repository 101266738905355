import React from 'react';
import { Typography } from '@material-ui/core';
import { ADD_AUTH_PAYLOAD } from 'store/actions/auth';

interface PROPS {
}

const YourResults = (props: PROPS) => {
    const authPayload = JSON.parse(localStorage.getItem(ADD_AUTH_PAYLOAD));
    
    return (
        <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 50 }}>
            <Typography variant={'h6'} style={{ fontWeight: 'bold', fontSize: '32px', marginBottom: 20 }}>{authPayload && authPayload.name && authPayload.name.trim().length > 0 ? authPayload.name.trim() + ', ' : ''}Congratulations on completing your survey!</Typography>
            <Typography variant={'h6'} style={{ fontSize: '18px', marginBottom: 20 }}>Below, you’ll find your skills map and a key to help you interpret your results.</Typography>
            <Typography variant={'h6'} style={{ fontSize: '18px', marginBottom: 5 }}>Each honeycomb icon represents a specific skill. You’ll find that some of your skills are lighter than others.</Typography>
            <Typography variant={'h6'} style={{ fontSize: '18px' }}>The darker the color, the higher you scored.</Typography>
        </div>
    );
};

export default YourResults;
