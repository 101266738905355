import React from 'react';
import { QuestionEvent } from './SkillsAssessmentState';
import ImageWithText from './ImageWithText';
import AutoSizer from 'react-virtualized-auto-sizer';

interface PROPS {
    questionEvent: QuestionEvent
}
const QuestionImage = (props: PROPS) => {
    const questionEvent = props.questionEvent;

    if (!questionEvent) return null;

    return (
        <div style={{ flex: '1 1 auto', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', width: '100%', height: '100%', overflow: 'hidden' }}>
            <AutoSizer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
                {({ height, width }) => {
                    const padding = 0;

                    const questionSrcWidth = 265;
                    const questionSrcHeight = 150;

                    const questionWidthRatio = questionSrcWidth / questionSrcHeight;

                    let targetHeight = height - padding;
                    let targetWidth = width - padding;

                    let questionHeight = targetHeight;
                    let questionWidth = questionHeight * questionWidthRatio;

                    let realHeight = questionHeight + padding;
                    let realWidth = questionWidth;

                    if (realWidth > targetWidth || realHeight > targetHeight) {
                        const reductionRatio = realHeight > targetHeight ? targetHeight / realHeight : targetWidth / realWidth;

                        questionHeight = questionHeight * reductionRatio;
                        questionWidth = questionWidth * reductionRatio;

                        realHeight = questionHeight + padding;
                        realWidth = questionWidth;
                    }

                    return (
                        <div id={'wrapper'} style={{ height: realHeight, width: realWidth, display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden', margin: 'auto' }}>
                            <div style={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', paddingRight: padding }}>
                                <ImageWithText
                                    src={questionEvent.questionImageUrl}
                                    text={'Examine the image'}
                                    disabled={true}
                                    width={questionWidth} height={questionHeight}
                                />
                            </div>
                        </div>
                    );
                }}
            </AutoSizer>
        </div>
    );
};

export default QuestionImage;
