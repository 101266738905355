import {
	ADD_AUTH_PAYLOAD,
	ADD_INPUT_SEARCH_PARAMS,
	ADD_OUTPUT_SEARCH_PARAMS,
	AUTH_ERROR,
	AUTH_REMOVE_ERROR,
	CHANGE_PAGE,
	CLEAR_ERRORS,
	FINISHING_AUTH,
	GET_PUBLIC_CONFIG,
	HANDOFF_TOKEN,
	LOADING_STATE_CHANGE,
	NO_PERMISSIONS_ERROR,
	RESTORE_CACHE,
	STARTING_AUTH,
	DISMISS_SYSTEM_MESSAGE,
} from 'store/actions/auth';
import AppConfigData from 'types/brainCloud/AppConfigData';

const initialState: AppConfigData = {
	waitingOnStateChange: false,
	page: '',
	hasPermissionError: undefined,
	appState: {isAuthing: false},
	urlInputArguments: null,
};

const AuthReducer = (state = initialState, action: any) => {
	let newState = Object.assign({}, state);

	const type = action.type;
	const payload = action.payload;

	if (type === ADD_OUTPUT_SEARCH_PARAMS) {
		newState.urlOutputArguments = {...newState.urlOutputArguments, ...payload};
	}

	if (type === ADD_INPUT_SEARCH_PARAMS) {
		newState.urlInputArguments = {...newState.urlInputArguments, ...payload};

		localStorage.setItem(ADD_INPUT_SEARCH_PARAMS, JSON.stringify(payload));
	}

	if (type === CHANGE_PAGE) {
		newState.page = payload;
	}

	if (type === LOADING_STATE_CHANGE) {
		newState.waitingOnStateChange = true;
	}

	if (type === RESTORE_CACHE) {
		newState = {
			...payload,
		};
	}

	if (type === CLEAR_ERRORS) {
		delete newState.hasPermissionError;

		newState.errors = [];

		localStorage.removeItem(NO_PERMISSIONS_ERROR);
	}

	if (type === NO_PERMISSIONS_ERROR) {
		newState.hasPermissionError = 'This training is not assigned to you. Contact your administrator.';

		if (payload && typeof(payload) === 'string' && payload.trim().length > 0) {
			newState.hasPermissionError = payload;
			// console.log("Encountered NO_PERMISSIONS_ERROR", window.location.href, newState.hasPermissionError, state.hasPermissionError);
		} 
		
		localStorage.setItem(NO_PERMISSIONS_ERROR, newState.hasPermissionError);

		const isVr = localStorage.getItem('isVr') === 'true';

		// window.location.href = window.location.origin + (isVr ? '/vr/' : '/');
	}

	if (type === GET_PUBLIC_CONFIG) {
		newState.publicAppData = payload;
	}

	if (type === STARTING_AUTH) {
		newState.appState.isAuthing = true;
		newState.errors = [];
	}

	if (type === ADD_AUTH_PAYLOAD) {
		newState.sessionToken = payload;
	}

	if (type === FINISHING_AUTH) {
		newState.appState.isAuthing = false;
		newState.errors = [];
	}

	if (type === AUTH_ERROR) {
		if (!newState.errors) {
			newState.errors = [];
		}
		newState.errors.push(payload);
		newState.appState.isAuthing = false;
	}

	if (type === AUTH_REMOVE_ERROR) {
		if (newState.errors.length > 0) {
			newState.errors.shift();
		}
	}

	if (type === HANDOFF_TOKEN) {
		newState.waitingOnStateChange = false;

		newState.handOffToken = payload;
	}

	if (type === 'GENERATE_JWT') {
		alert(type);

		newState = Object.assign({}, payload);
	}

	if (type === 'GENERATE_PIN') {
		alert(type);

		newState = Object.assign({}, payload);
	}

	if (type === 'AUTH_LOGOUT') {
		alert(type);

		newState = Object.assign({}, payload);
	}

	if (type === DISMISS_SYSTEM_MESSAGE) {
		newState.systemMessageDismissed = true;
	}

	if (action.callback && typeof action.callback === 'function') {
		action.callback();
	}

	return newState;
};

export default AuthReducer;
