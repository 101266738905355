import ApplicationState, {mapStateToProps} from 'types/store';
import {DispatchCalls, mapDispatchToProps} from 'store';
import React, {useCallback, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect, useLocation} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from 'ui/Footer';
import LoginPage from 'sections/login';
import PageError from './PageError';
import PinPage from 'sections/pin';
import WelcomePage from 'sections/welcome';
import {compose} from 'redux';
import decodeJwt from 'jwt-decode';
import useLogin from '../hooks/useLogin';
import ForgotPasswordPage from './login/ForgotPasswordPage';
import PageNavigator from '../components/logic/PageNavigator';
import InitialQuery from '../components/logic/InitialQuery';
import LoginNavigator from '../components/logic/LoginNavigator';
import { project } from 'utils';
import TermsOfService from 'sections/TermsOfService';

import {passportTheme} from 'theme';
import { ThemeProvider } from '@material-ui/core';
import { BrandingCSSData } from 'types/brainCloud/BrandingData';
import { NO_PERMISSIONS_ERROR } from 'store/actions/auth';
import SkillsAssessmentSurvey from './SkillsAssessmentSurvey';
import { UserManager } from 'oidc-client';
import analytics from 'analytics/analytics';

const convertBrandingDataToCSS = (data?: BrandingCSSData): string => {
	if (!data) return;

	// console.log("Converting branding data", data);

    /* Support for all other CSS variables */
    let result = ':root {\n';

    Object.keys(data).forEach(key => {
        const value = data[key];

        if (value) {
            result = result + '--' + key + ': ' + value + ';\n';
        }
    });

    result = result + '}\n';
    
    return result;
}

const isVr = () => {
	return localStorage.getItem('isVr') === 'true';
}

const isSkillsAssessmentSurvey = () => {
	return localStorage.getItem('isSkillsAssessmentSurvey') === 'true';
}

const termsOfService = () => {
	const termsOfServiceRaw = localStorage.getItem(project + '-termsOfService');
	const termsOfService = termsOfServiceRaw ? JSON.parse(termsOfServiceRaw) : null;
	return termsOfService;
}

const requestedTrainingModules = () => {
	return localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];
}

const noPermissionsError = () => {
	return localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined' ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null;
}

const App = (props) => {
	const login = useLogin();

	// Restore the cache
	useEffect(() => {
		restoreCache();
		analytics.page();
	}, []);

	const {getPublicConfig} = props;

	const {appConfigData, brandingData}: ApplicationState = props;

	const {restoreCache}: DispatchCalls = props;

	const [token, setToken] = useState(localStorage.getItem('token'));

	// const isVr = localStorage.getItem('isVr') === 'true';
	// const requestedTrainingModules = localStorage.getItem('requestedTrainingModules') ? localStorage.getItem('requestedTrainingModules').split(',') : [];
	// const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR) != null && localStorage.getItem(NO_PERMISSIONS_ERROR) !== 'undefined' ? localStorage.getItem(NO_PERMISSIONS_ERROR) : null;
	
	const [isReady, setIsReady] = useState(false);

	// Set null packet id to zero
	if (!localStorage.getItem('packetId')) {
		localStorage.setItem('packetId', (0).toString());
	}

	// TODO Merge this with the refresh code
	useEffect(() => {
		if (appConfigData && appConfigData.sessionToken) {
			let expireTime = appConfigData.sessionToken.exp * 1000;

			if (expireTime - Date.now() < 0) {
				//	window.location.href = '/';
				localStorage.clear();
				if (noPermissionsError()) localStorage.setItem(NO_PERMISSIONS_ERROR, noPermissionsError());
			}
			let json: any = {};
			json.sessionExp = expireTime;
			json.now = Date.now();

			json.delta = expireTime - Date.now();
		}
	});

	// Check brainCloud session expiry
	useEffect(() => {
		// TODO need to wait until reauthenticate is implemented

		var timer;
		const startTimer = () => {
			timer = setInterval(() => {
				if (appConfigData.waitingOnStateChange) {
					return;
				}

				let expire = appConfigData.sessionToken.exp * 1000;

				let exp = appConfigData.sessionToken.exp;
				let iat = appConfigData.sessionToken.iat;

				if (exp - iat < 180) {
					return;
				}

				let timeLeft = parseInt(expire.toString()) - Date.now();

				let email = window.localStorage.getItem('externalId');

				if (timeLeft > 60 && email) {
					login({username: email, password: appConfigData.sessionToken.token});
				}
			}, 1000);
		};

		const stopTimer = () => {
			clearInterval(timer);
		};

		if (false && appConfigData.sessionToken) {
			startTimer();
			return () => {
				stopTimer();
			};
		}
	}, [appConfigData.handOffToken]);

	// Get any redirect token from Okta
	useEffect(() => {
		let hash = window.location.hash;

		if (hash.includes('id_token')) {
			console.debug("Handling Okta Redirect", window.location.href);

			var hashes = hash.split('&');
			var id_token = '';
			var access_token = '';
			for (var i = 0; i < hashes.length; i++) {
				if (hashes[i].includes('id_token')) {
					id_token = hashes[i].split('=')[1];
				}
				if (hashes[i].includes('access_token')) {
					access_token = hashes[i].split('=')[1];
				}
			}

			var jwtData = decodeJwt(id_token);
			var externalId = jwtData?.email || jwtData?.sub;
			
			window.localStorage.setItem('externalId', externalId);
			window.localStorage.setItem('id_token', id_token);
			window.localStorage.setItem('access_token', access_token);
			
			// window.location.hash = '';
			
			login({ username: externalId, password: id_token });			

			return;
		}
	}, [window.location.href]);

	// Get any redirect userId/responseId for SAML
	useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);

		if (params.get('userId') && params.get('responseId')) {
			console.debug("Handling SAML Redirect", window.location.href);

			const userId = params.get('userId');
			const responseId = params.get('responseId');

			window.localStorage.setItem('externalId', userId);
			window.localStorage.setItem('responseId', responseId);

			login({username: userId, password: responseId});

			return;
		}
	}, [window.location.href]);

	// When location changes in app send page view
	useEffect(() => {
	  analytics.page();
	}, [window.location.href]);

	// Check for brainCloud login token
	useEffect(() => {
		if (
			appConfigData.sessionToken &&
			token != appConfigData.sessionToken.token
		) {
			setToken(appConfigData.sessionToken.token);
		}
	});

	const performOpenIdLogout = useCallback((id_token?: string) => {
		var userManager = null;

		const publicAppData = appConfigData.publicAppData;
		
		if (publicAppData?.okta?.scopes) {
			const login_hint = decodeJwt(id_token).login_hint;

			var cleanedScopes = publicAppData.okta.scopes
				? publicAppData.okta.scopes.replace(/','/g, ' ')
				: 'openid email profile';

			userManager = new UserManager({
				authority: publicAppData.okta.issuer,
				client_id: publicAppData.okta.clientId,
				redirect_uri: `${window.location.origin}/implicit/callback`,
				post_logout_redirect_uri: `${window.location.origin}/implicit/logout`,
				response_type: 'token id_token',
				scope: cleanedScopes,
				extraQueryParams: login_hint || id_token ? {
					'logout_hint': login_hint || undefined,
					'id_token_hint': id_token || undefined,
					'client_id': publicAppData.okta.clientId,
				} : undefined,
				// When CORS prevents discovery, we must use additional settings from the publicAppData.
				metadata: publicAppData?.okta?.authorization_endpoint
					? {
						  issuer: publicAppData?.okta?.issuer,
						  authorization_endpoint: publicAppData?.okta?.authorization_endpoint,
						  userinfo_endpoint: publicAppData?.okta?.userinfo_endpoint,
						  end_session_endpoint: publicAppData?.okta?.end_session_endpoint,
					  }
					: undefined,
			});

			// Check if dynamic discovery is being used
			if (userManager.settings.metadata) {
				console.log("Manual discovery is being used.");
			} else {
				console.log("Dynamic discovery (automatic) is being used.");
			}

			userManager.signoutRedirect();
		}
	
	}, [appConfigData]);

	// Handle Okta logout after brainCloud login
	useEffect(() => {
		var id_token = localStorage.getItem('id_token');
		var externalId = localStorage.getItem('externalId');
		var responseId = localStorage.getItem("responseId");

		if (
			appConfigData.publicAppData &&
			appConfigData.publicAppData.okta &&
			id_token &&
			id_token != '0' &&
			['/implicit/callback', '/vr/implicit/callback'].includes(window.location.pathname)
		) {
			console.debug("Attempting Implicit Okta Logout", window.location.pathname, appConfigData.sessionToken, externalId, id_token);

			localStorage.removeItem('id_token');

			performOpenIdLogout(id_token);
		}

		if (
			appConfigData.publicAppData &&
			appConfigData.publicAppData.saml &&
			responseId &&
			responseId != '0' &&
			appConfigData.sessionToken &&
			appConfigData.sessionToken.authPayload &&
			appConfigData.sessionToken.authPayload.logoutRequestUrl &&
			['/implicit/callback', '/vr/implicit/callback'].includes(window.location.pathname)
		) {
			console.debug("Attempting Implicit SAML Logout", window.location.pathname, appConfigData.sessionToken, externalId, responseId);

			localStorage.removeItem('responseId');

			// localStorage.clear();
			let redirect = `${appConfigData.sessionToken.authPayload.logoutRequestUrl}`;
			// if (isVr) redirect = `${appConfigData.publicAppData.okta.issuer}/v1/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${window.location.origin}/vr/implicit/logout`;
			// console.log("Redirect", redirect, id_token);
			window.location.href = redirect;
		}
	}, [token]);

	// Get the public app config
	useEffect(() => {
		if (getPublicConfig != null) {
			console.log("Fetching Public App Config");

			getPublicConfig();

			setTimeout(() => {
				console.log("Ready!!!");
				setIsReady(true);
			}, 500);
		}
	}, []);

	if (
		appConfigData &&
		appConfigData.publicAppData &&
		appConfigData.publicAppData.error
	) {
		return <PageError error={appConfigData.publicAppData.error} />;
	}

	const runwayOrgconfigRaw = localStorage.getItem('runwayOrgConfig');
	const runwayOrgConfig = runwayOrgconfigRaw ? JSON.parse(runwayOrgconfigRaw) : null;
	// const termsOfServiceRaw = localStorage.getItem(project + '-termsOfService');
	// const termsOfService = termsOfServiceRaw ? JSON.parse(termsOfServiceRaw) : null;
	// let redirectToTermsOfService = false;

	// if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true' && termsOfService() && !termsOfService().userAccepted) {
	// 	redirectToTermsOfService = true;
	// }

	return (
		<div style={{ display: isReady ? 'block' : 'none' }}>
			{brandingData &&
				<style>
					{convertBrandingDataToCSS(brandingData)}
				</style>
			}
			<ThemeProvider theme={passportTheme}>
				<div
					style={{
						backgroundImage: 'linear-gradient(var(--page-background-gradient-start), var(--page-background-gradient-end))',
						color: 'var(--page-primary-font-color)'
					}}
				>
					<div
						style={{
							position: 'fixed',
							height: '100vh',
							width: '100vw',
							backgroundImage: 'linear-gradient(var(--page-background-gradient-start), var(--page-background-gradient-end))',
						}}
					/>

					<CssBaseline />

					<div
						style={{
							fontSize: '18px',
							height: '100vh',
							position: 'relative',
							display: 'flex',
							flexDirection: 'row'
						}}
					>
						<div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
							<Router>
								<InitialQuery />

								<PageNavigator />
								<LoginNavigator />

								<Switch>
									<Route
										path='/skillsAssessmentSurvey'
										render={() => {
											if (noPermissionsError() || !token) {
												return <LoginPage {...props} />;
											} else {
												return <SkillsAssessmentSurvey />;
											}
										}}
									/>
									<Route
										path='/termsOfService'
										render={() => {
											// console.log("Rendering TermsOfService Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true' && termsOfService() && !termsOfService().userAccepted) {
												if (noPermissionsError()) {
													if (isVr()) {
														if (requestedTrainingModules().length > 0) {
															return <Redirect to={"/vr/?trainingModules=" + requestedTrainingModules().join(',')} />;
														} else {
															return <Redirect to="/vr/" />;
														}
													} else {
														if (requestedTrainingModules().length > 0) {
															return <Redirect to={"/?trainingModules=" + requestedTrainingModules().join(',')} />;
														} else {
															return <Redirect to="/" />;
														}
													}
												} else {
													return <TermsOfService {...props} isVr={isVr()} />;
												}
											} else {
												if (isVr()) {
													return <Redirect to='/vr' />;
												} else {
													return <Redirect to='/welcome' />;
												}
											}
										}}
									/>
									<Route
										path='/vr'
										render={() => {
											// console.log("Rendering VR Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											return <LoginPage {...props} />;
										}}
									/>
									<Route
										path='/welcome'
										render={() => {
											// console.log("Rendering Welcome Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											if (isVr()) {
												if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true' && termsOfService() && !termsOfService().userAccepted) return <Redirect to="/termsOfService" />;

												if (noPermissionsError()) {
													if (isVr()) {
														if (requestedTrainingModules().length > 0) {
															return <Redirect to={"/vr/?trainingModules=" + requestedTrainingModules().join(',')} />;
														} else {
															return <Redirect to="/vr/" />;
														}
													} else {
														if (requestedTrainingModules().length > 0) {
															return <Redirect to={"/?trainingModules=" + requestedTrainingModules().join(',')} />;
														} else {
															return <Redirect to="/" />;
														}
													}
												}

												return <WelcomePage isVr={true} isSkillsAssessmentSurvey={false} />;
											}

											if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true' && termsOfService() && !termsOfService().userAccepted) return <Redirect to="/termsOfService" />;

											if (noPermissionsError()) {
												if (isVr()) {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/vr/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/vr/" />;
													}
												} else {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/" />;
													}
												}
											}

											return <WelcomePage isVr={false} isSkillsAssessmentSurvey={isSkillsAssessmentSurvey()} />;
										}}
									/>
									<Route
										path='/pin'
										render={() => {
											// console.log("Rendering Generate PIN Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											if (runwayOrgConfig && runwayOrgConfig.config && runwayOrgConfig.config.TermsOfServiceRequired === 'true' && termsOfService() && !termsOfService().userAccepted) return <Redirect to="/termsOfService" />;

											if (noPermissionsError()) {
												if (isVr()) {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/vr/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/vr/" />;
													}
												} else {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/" />;
													}
												}
											} else {
												return <PinPage />;
											}
										}}
									/>
									<Route
										path='/implicit/logout'
										render={() => {
											// console.log("Rendering implicit logout Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											if (noPermissionsError()) {
												if (isVr()) {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/vr/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/vr/" />;
													}
												} else {
													if (requestedTrainingModules().length > 0) {
														return <Redirect to={"/?trainingModules=" + requestedTrainingModules().join(',')} />;
													} else {
														return <Redirect to="/" />;
													}
												}
											}

											if (isVr()) {
												if (requestedTrainingModules().length > 0) {
													return <Redirect to={"/pin?trainingModules=" + requestedTrainingModules().join(',')} />;
												} else {
													return <Redirect to="/pin" />;
												}
											} else {
												if (requestedTrainingModules().length > 0) {
													return <Redirect to={"/welcome?trainingModules=" + requestedTrainingModules().join(',')} />;
												} else {
													return <Redirect to="/welcome" />;
												}
											}
											// return <LoginPage {...props} />;
										}}
									/>
									<Route path='/implicit/callback' render={() => {
										localStorage.removeItem(NO_PERMISSIONS_ERROR);

										return <LoginPage {...props} />;
									}} />

									<Route
										path='/forgot-password'
										render={() => <ForgotPasswordPage forceEmail {...props} />}
									/>

									<Route
										path='/'
										render={() => {
											// console.log("Rendering non-VR Route", termsOfService(), requestedTrainingModules(), noPermissionsError());

											return <LoginPage {...props} />;
										}}
									/>
								</Switch>
							</Router>
						</div>

						<Footer />
					</div>
				</div>
			</ThemeProvider>
		</div>
	);
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
