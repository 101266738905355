import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {getPublicConfig, removeError, restoreCache} from './actions/auth';

const middleware = [thunk];

export default createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middleware))
);

type EmptyFunctionType = (callback?: any) => Promise<string>;

export interface DispatchCalls {
	restoreCache?: EmptyFunctionType;
	getPublicConfig?: EmptyFunctionType;
	removeError?: EmptyFunctionType;
}

export const mapDispatchToProps = (dispatch: Function) => {
	return {
		restoreCache: (callback?: any) => dispatch(restoreCache(callback)),
		getPublicConfig: (callback?: any) => dispatch(getPublicConfig(callback)),
		removeError: (callback?: any) => dispatch(removeError(callback)),
	};
};

export const DispatchType = {
	restoreCache: 'restoreCache',
	getPublicConfig: 'getPublicConfig',
	removeError: 'removeError',
};
