import {Button, CircularProgress, Typography} from '@material-ui/core';
import {Offline} from 'react-detect-offline';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PrimarySearchAppBar from '../appbar';
import {ADD_OUTPUT_SEARCH_PARAMS, NO_PERMISSIONS_ERROR, ADD_INPUT_SEARCH_PARAMS} from '../../store/actions/auth';
import useGeneratePin from '../../hooks/useGeneratePin';
import ErrorText from '../../components/text/ErrorText';

import get from 'lodash/get';
import AppConfigData from '../../types/brainCloud/AppConfigData';
import {LogoutButton} from '../../components/ui/LogoutButton';

const WelcomePage = (props) => {
	const dispatch = useDispatch();

	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	const hasPermissionError =
		appConfigData.hasPermissionError !== undefined &&
		appConfigData.hasPermissionError !== '' &&
		appConfigData.hasPermissionError !== null &&
		appConfigData.hasPermissionError !== 'undefined';

	/**
	 * Check for handoff token expiry
	 */
	useEffect(() => {
		if (hasPermissionError) {
			return;
		}

		if (appConfigData.handOffToken) {
			let urlInputSearchParams: any = {};
			try {
				urlInputSearchParams = JSON.parse(localStorage.getItem(ADD_INPUT_SEARCH_PARAMS));
			} catch (error) {}

			dispatch({
				type: ADD_OUTPUT_SEARCH_PARAMS,
				payload: {
					handOffToken: appConfigData.handOffToken.securityToken,
					pin: appConfigData.handOffToken.securityToken,
					...urlInputSearchParams
				},
			});
		}
	}, [appConfigData.handOffToken]);

	useEffect(() => {
		const isFirstLoad = localStorage.getItem('isFirstLoad') === 'true';

		if (isFirstLoad) {
			localStorage.setItem('isFirstLoad', 'false');
		}
	}, []);

	const isVR = localStorage.getItem('isVr') === 'true';

	const isGeneratingPin = localStorage.getItem('generatingPin') === 'true';
	const noPermissionsError = localStorage.getItem(NO_PERMISSIONS_ERROR);
	if (isGeneratingPin || (noPermissionsError != null && noPermissionsError !== 'undefined')) {
		return null;
	}

	return (
		<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
			<PrimarySearchAppBar />

			<div style={{ overflow: 'auto' }}>
			{isVR &&
				<div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<Typography style={{ fontSize: 24, fontWeight: 'bold' }}>Login Successful</Typography>
				</div>
			}

			{!isVR &&
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<div
						style={{
							minHeight: '155px',
							height: '155px',
							maxWidth: '375px',
							marginTop: '64px',
							marginBottom: '20px',
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<Seperator />
						<Flex />
						<PinDisplay />
						<Flex />
						<Seperator />
					</div>

					<Title />

					<Body />

					<div style={{flex: 1}} />

					<LogoutButton />
				</div>
			}
			</div>
		</div>
	);
};

export default WelcomePage;

function Flex() {
	return (
		<div
			style={{
				flex: 1,
			}}
		/>
	);
}

function Seperator() {
	return (
		<div
			style={{
				width: '100%',
				borderTop: '1px solid var(--separator-color)',
			}}
		/>
	);
}

function Title() {
	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	return (
		<Typography variant='h2'>
			{get(appConfigData, 'publicAppData.passport.pin.title')}
		</Typography>
	);
}

function Body() {
	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	return (
		<div
			style={{
				flex: '1 1 auto',
				maxWidth: '375px',
				overflowY: 'auto',
				marginBottom: '20px',
			}}
		>
			<Typography
				variant='body2'
				gutterBottom
				style={{
					width: '100%'
				}}
				dangerouslySetInnerHTML={{
					__html: get(appConfigData, 'publicAppData.passport.pin.body'),
				}}
			/>
		</div>
	);
}

function PinHeader({hasPinExpired, expiryMessage, colouredPin}) {
	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	const pin = get(appConfigData, 'handOffToken.securityToken');

	const pinLoading = !pin || pin == '';

	if (pinLoading) {
		return (
			<Typography
				style={{
					//border: '1px solid  rgba(0, 18, 47, 1)',
					margin: 'auto',
					textAlign: 'center',
				}}
				variant='h4'
				gutterBottom
			>
				<CircularProgress size={28} thickness={2} />

				<Offline>
					<div style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center'
					}}>
						<ErrorText>No connection. Please check your internet.</ErrorText>
					</div>
				</Offline>
			</Typography>
		);
	}

	if (hasPinExpired) {
		return (
			<Typography
				style={{
					//border: '1px solid  rgba(0, 18, 47, 1)',
					margin: 'auto',
					padding: '20px',
					textAlign: 'center',
					position: 'relative',
					flex: 2,
				}}
				variant='h3'
				gutterBottom
			>
				{expiryMessage}
			</Typography>
		);
	}

	return (
		<Typography
			style={{
				//border: '1px solid  rgba(0, 18, 47, 1)',
				margin: 'auto',
				textAlign: 'center',
				fontFamily: 'monospace',
				color: 'var(--pin-letter-font-color)'
			}}
			variant='h4'
			gutterBottom
		>
			<div dangerouslySetInnerHTML={{__html: colouredPin}} />
		</Typography>
	);
}

function PinDisplay() {
	const appConfigData = useSelector((state: {appConfigData: AppConfigData}) => {
		return state.appConfigData;
	});

	const generatePin = useGeneratePin();

	const hasPermissionError =
		appConfigData.hasPermissionError !== undefined &&
		appConfigData.hasPermissionError !== '' &&
		appConfigData.hasPermissionError !== null &&
		appConfigData.hasPermissionError !== 'undefined';

	const [hasPinExpired, setHasPinExpired] = useState(false);
	const [expiryMessage, setExpiryMessage] = useState('');

	useEffect(() => {
		if (hasPermissionError) {
			return;
		}

		var timer;
		const startTimer = () => {
			timer = setInterval(() => {
				if (hasPermissionError) {
					return;
				}

				var expire = appConfigData.handOffToken.expireDate;

				if (!expire) {
					return;
				}

				let timeLeft = parseInt(expire.toString()) - Date.now();

				var expiryMessage = '';

				var nowExpired = timeLeft <= 0;

				if (nowExpired) {
					expiryMessage = 'Pin has expired';
				} else {
					var date = new Date(null);
					date.setMilliseconds(timeLeft);
					expiryMessage = `Pin expires in: ${date.toISOString().substr(14, 5)}`;
				}

				setHasPinExpired(nowExpired);

				localStorage.setItem(
					'pin_hasPinExpired',
					nowExpired ? 'true' : 'false'
				);

				localStorage.setItem('pin_expiryMessage', expiryMessage);
				setExpiryMessage(expiryMessage);
			}, 1);
		};

		const stopTimer = () => {
			clearInterval(timer);
		};

		if (appConfigData.handOffToken) {
			startTimer();
			return () => {
				stopTimer();
			};
		}
	}, [appConfigData.handOffToken]);

	const pin = get(appConfigData, 'handOffToken.securityToken');

	let regex = new RegExp('[a-zA-Z0-9]{3,3}(?!$)', 'g');
	let dashedToken = pin;
	let spacerChar = '-';
	let formattedPin = dashedToken
		? dashedToken.replace(regex, `$&${spacerChar}`)
		: null;

	const colouredPin = formattedPin
		? formattedPin.replace(
				/\d+/g,
				(match) =>
					`<span style="color: var(--pin-number-font-color); display: inline-flex;"> ${match} </span>`
		  )
		: '';

	return (
		<React.Fragment>
			<PinHeader
				hasPinExpired={hasPinExpired}
				expiryMessage={expiryMessage}
				colouredPin={colouredPin}
			/>

			{!pin || pin == '' ? null : hasPinExpired ? (
				<div>
					<Button id={'refresh-pin-button'} onClick={generatePin} variant='contained'>
						Refresh
					</Button>
					<Offline>
						<div style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							textAlign: 'center'
						}}>
							<ErrorText>No connection. Please check your internet.</ErrorText>
						</div>
					</Offline>
				</div>
			) : (
				<Typography
					style={{
						//border: '1px solid  rgba(0, 18, 47, 1)',
						margin: 'auto',
						position: 'relative',
						textAlign: 'center',
						color: 'var(--pin-expiration-font-color)',
					}}
					variant='h3'
					gutterBottom
				>
					{expiryMessage}
				</Typography>
			)}

			<div
				style={{
					flex: 1,
				}}
			/>
		</React.Fragment>
	);
}
